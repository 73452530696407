import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "./context/AuthProvider";
import { DataProvider } from "./context/DataContext";
import { PinsProvider } from "./context/PinsContext";
import { AreaChartProvider } from "./context/AreaChartContext";
import { AiAccuracyProvider } from "./context/AiAccuracy";
import { SignalRProvider } from "./context/SignalRContext";
import theme from "./styles/theme";
import GlobalStyle from "./styles/globalStyle";
import ErrorBoundary from "components/ErrorBoundaries";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "assets/icomoon/style.css";
import "react-toastify/dist/ReactToastify.css";
import { UnityProvider } from "context/UnityContext";
import { EnergyProvider } from "context/EnergyContext";

const App: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SignalRProvider>
          <DataProvider>
            <AiAccuracyProvider>
              <UnityProvider>
                <EnergyProvider>
                  <PinsProvider>
                    <AreaChartProvider>
                      <ThemeProvider theme={theme}>
                        <ErrorBoundary>
                          <GlobalStyle />
                          <Routes>
                            <Route path="/*" element={<AppRoutes />} />
                          </Routes>
                          <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                          />
                        </ErrorBoundary>
                      </ThemeProvider>
                    </AreaChartProvider>
                  </PinsProvider>
                </EnergyProvider>
              </UnityProvider>
            </AiAccuracyProvider>
          </DataProvider>
        </SignalRProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
