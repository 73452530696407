import EditProfile from "pages/AccountProfile/Edit";
import ResetPassword from 'pages/AccountProfile/ResetPassword';

const Profile = ({ type, handleOpenModal }) => {
   return (    
    <div className="card form-container">
        {type === 'reset-password' && <ResetPassword handleOpenModal={handleOpenModal} />}
        {type === 'profile' && <EditProfile handleOpenModal={handleOpenModal} />}
    </div>
   ); 
};

export default Profile;