import { ITagExpression, useData } from "context/DataContext";
import { TagLists } from "pages/Tags";
import { useCallback, useEffect, useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAllExpressions, getAllTags, getAllTagsWithParent } from "service/data";
import { useGet, useUpdate } from "helpers/assets/apiHook";
import { ExpressionLists } from "pages/Expressions";
import useUnity, { UnityContext, UnityProvider } from "context/UnityContext";
import { useUnityContext } from "react-unity-webgl";
import WebGLContext from "context/WebGLContext";
import { useNavigate } from "react-router-dom";


const oillocs=[
    {name:"UA Spheroids 106", locationid:0,id:1},
    {name:"South Stab 120", locationid:1,id:2},
    {name:"North Stab 110", locationid:2,id:3},
    {name:"3-Phase Separators 429", locationid:3,id:4},
    {name:"APS-6 121", locationid:4, id:5},
    {name:"APS-7 142", locationid:5, id:6},
    {name:"APS-8 190", locationid:6, id:7},
    {name:"ATF-1 102", locationid:7, id:5},
    {name:"ATF-2 119", locationid:8, id:295}
]

const ngllocs=[
    {name:"North NGL 334", locationid:9, id:10},
    {name:"South NGL 340", locationid:10, id:10},
    {name:"South NGL 461", locationid:11, id:10},
    {name:"South NGL 462", locationid:12, id:10},
    {name:"South NGL 499", locationid:13, id:10},
]

const utillocs=[
    {name:"Power Plant M58", locationid:14, id:12},
    {name:"North Utilities", locationid:15, id:12},
    {name:"South Utilities", locationid:16, id:12},
]

const Unitywindow=()=>{

  const navigate = useNavigate();

  function handleCacheControl(url) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
  } = useUnityContext({
    loaderUrl: "Build/Unity/WebGL.loader.js",
    dataUrl: "Build/Unity/WebGL.data",
    frameworkUrl: "Build/Unity/WebGL.framework.js",
    codeUrl: "Build/Unity/WebGL.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: "Build/Unity/StreamingAssets",
  });

  
    const {unityLoaded, setUnityLoaded, currid, setcurrid, anchorEl, setAnchorEl} = useUnity();

    const [refresh, setRefresh] = useState<boolean>(true);
    const parentId = 0;
    const {
      data,
      loader: load,
      message: messageRes,
      error: errorRes,
    } = useGet<TagLists>(getAllTagsWithParent, parentId, refresh);

    
  // useEffect(() => {

  //     if(currid!=null && !unityLoaded ){
  //       const url = `/3d-dashboard?locationId=${currid}`;
  //       window.open(url, "_self");
  //     }
  // }, [currid]);


  const btnfn=(id)=>{

    if(unityLoaded){
      setcurrid(id);    
      // setTimeout(() => {setopen(false);}, 1000);
      setAnchorEl(null);
    }
    else{
      setcurrid(id);    
      const url = `/3d-dashboard?locationId=${id}`;
      setAnchorEl(null);
      navigate(url);

    }
  }


    return(
        <div className='row' style={{position:"relative", zIndex:"999999"}}>
            <div className='col'>
            {oillocs.map((vals, index) => (
                vals && (
                    <div>
                        <h4 style={{color:'#989F00',cursor:'pointer'}} onClick={()=>{btnfn(oillocs[index].locationid);}}>{oillocs[index].name}</h4>
                    </div>
                    )
                ))} 
            </div>

            <div className='col'>
            {ngllocs.map((vals, index) => (
                vals && (
                    <div>
                        <h4 style={{color:'#66C1C5',cursor:'pointer'}} onClick={()=>btnfn(ngllocs[index].locationid)}>{ngllocs[index].name}</h4>
                    </div>
                    )
                ))} 
            </div>

            <div className='col'>
            {utillocs.map((vals, index) => (
                vals && (
                    <div>
                        <h4 style={{color:'#FF6C00',cursor:'pointer'}} onClick={()=>btnfn(utillocs[index].locationid)}>{utillocs[index].name}</h4>
                    </div>
                    )
                ))} 
            </div>
        </div>

    )

}

export default Unitywindow;