import axios from "axios";

const baseUrl = localStorage.getItem("baseUrl");
if (baseUrl) {
  axios.defaults.baseURL = baseUrl;
} else {
  fetch(`${process.env.PUBLIC_URL}/config.json`)
    .then((res) => res.json())
    .then((data) => {
      localStorage.setItem("baseUrl", data.BASE_URL);
      axios.defaults.baseURL = data.BASE_URL;
    });
}

axios.interceptors.request.use(
  function (axios_config: any) {
    const userToken = localStorage.getItem("token");
    if (userToken) {
      axios_config.headers.Authorization = `Bearer ${
        JSON.parse(userToken).access_token
      }`;
    }
    return axios_config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      // return (window.location.href = "/");
      window.dispatchEvent(new Event("storage"));
    }
    if (axios.isCancel(error)) {
      return Promise.reject({
        response: {
          data: { error_description: "Cancelled" },
        },
      });
    }
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axios;
