export const BASE_URL = process.env.REACT_APP_BASE_URL;

const castToBoolean = (enValue: any) => {
    const stringBooleanOptions = {
        true: ['true', 'TRUE', '1'],
        false: ['false', 'FALSE', '0']
    };

    if(stringBooleanOptions.true.includes(enValue)) {
        return true;
    }
        
    return false;
};

export const REGISTER_WITH_EMAIL_CONFIRM = castToBoolean(process.env.REACT_APP_REGISTER_WITH_EMAIL_CONFIRMATION);

export const messages = {
    firstname: {
        max: 100,
        maxMessage: "First name must be at most 100 characters.",
    },
    lastname: {
        max: 100,
        maxMessage: "Last name must be at most 100 characters.",
    },
    password: {
        min: 10,
        max: 100,
        minMessage: "Password must be at least 10 characters",
        maxMessage: "Password must be at most 100 characters.",
        validMessage: "Password must contain the following characters - lowercase, uppercase, symbol, number. And must be at least 10 characters.",
        matchMessage: 'The two password fields didn’t match.',
    },
    phoneNumber: {
        invalid: "Please enter a valid mobile number"
    },
    account: {
        registerSuccess: "Account is created successfully.",
        resetSuccess: "Your password has been changed successfully.",
        resendConfirmSuccess: "Confirmation email has been resent successfully.",
        confirmedSuccess: "Your email is verified successfully.",
        alreadyConfirmedSuccess: "Your email is already verified."
    },
    profile: {
        updateSuccess: "Saved successfully"
    },
    tickets : {
        closeSuccess: "Ticket has been closed successfully.",
        rejectSuccess: "Ticket has been rejected."
    }
};

export const regex = {
    mobile: /^(\+\d{1,3}[- ]?)?\d{10}$/
}
//UI
export const inputRegexType = {
    TEXT_ONLY: 'TEXT_ONLY'
}

export const textInputRegex = {
    [inputRegexType.TEXT_ONLY]: /^[a-zA-Z]+/g,
}