import logoImg from "assets/images/logo.png";

interface SplashScreenInterface {
  open: boolean;
  logo?: boolean;
  flicker1?: string;
  flicker2?: string;
  status?: string;
}

export const SplashScreen: React.FunctionComponent<SplashScreenInterface> = ({
  open = false,
  logo = false,
  flicker1 = "LOAD",
  flicker2 = "ING",
  status,
}) => {
  if (open) {
    return (
      <>
        <div className="loader-container">
          <div className="loader-content">
            {logo && (
              <img src={logoImg} alt="Processhub" className="logo-medium" />
            )}
            <div className="loader">
              <span
                data-flicker-0={flicker1}
                data-flicker-1={flicker2}
                className="loader-text"
              >
                {flicker1 + flicker2}
              </span>
            </div>
          </div>
          <div className="loader-status">{status}</div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
