import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getAllData,
  getPinList,
  pinWidget,
  getExpressionsValueTypeInstant,
} from "service/data";
import { useAuth } from "context/AuthProvider";
import { useSignalR } from "./SignalRContext";
import { IChartData } from "./WebGLContext";
import { errorHandling } from "helpers/common";
import axios from "service/axios";
import {
  getExpressionsModified,
  getExpressionsModifiedLive,
  getTagsModifiedLive,
} from "service/modified";

export const DashBoardType = { React: 1, Unity: 2 };
export const PrefabType = { Bubble: 1, Note: 2 };
interface IDataContext {
  entireCollection: ICollection | null;
  plants: IPlant[];
  trains: ITrain[];
  units: IUnit[];
  equipments: IEquipment[];
  tags: ITagExpression[];
  expressions: ITagExpression[];
  isPastData: boolean;
  pastDate: string | null;
  pastDateHandler: (isPast: boolean, dateTime: string | null) => void;
  daySky: number;
  setDayUnity: (day: number) => void;
  sceneId: number;
  sceneSwitching: (id: number) => void;
  profileModal: boolean;
  profileHandler: (open: boolean) => void;
  UnitEquipmentBubbleData: () => IUnit[];
  getUnitInfo: (unitId: number) => IUnit | null;
  getUnitExpressionsInfo: (unitId: number) => ITagExpression[];
  getEquipmentInfo: (equipmentId: number) => IEquipment | null;
  getTagsInfo: (equipmentId: number) => ITagExpression[];
  getEquipmentExpressionsInfo: (equipmentId: number) => ITagExpression[];
  getTagInfo: (tagId: number) => ITagExpression | null;
  getExpressionInfo: (expressionId: number) => ITagExpression | null;
  getExpressionLists: (expressionIds: number[]) => ITagExpression[];
  chartInfo: (id: number, type: string) => ITagExpression | null;
  updateLiveData: () => void;
  loader: boolean;
  getUpdatedPinnedLists: () => void;
  pinChartHandler: (
    pinningStatus: boolean,
    tagId: number | null,
    expressionId: number | null,
    chartDetail: IChartData | ITagExpression | null,
    dashBoardType: number
  ) => void;
  isChartPinned: (id: number, type: string, dashBoardType: number) => boolean;
  getPinnedLists: (dashBoardType: number) => IUserPinDataLists[];
  pinnedLists: IUserPinLists[];
  pinMessage: IPinMessage | null;
}

interface ICollection {
  id: number;
  name: string;
  expressionLookup: ITagExpression[];
  trains: {
    id: number;
    name: string;
    expressionLookup: ITagExpression[];
    units: {
      id: number;
      name: string;
      nameIdentifier: string;
      isNormal: boolean;
      color: number;
      size: number;
      expressionLookup: ITagExpression[];
      equipments: {
        id: number;
        name: string;
        nameIdentifier: string;
        isNormal: boolean;
        color: number;
        size: number;
        type: string;
        expressionLookup: ITagExpression[];
        tags: ITagExpression[];
      };
    };
  }[];
  type: string;
}

interface IPlant {
  id: number;
  name: string;
  trainIds: number[];
  type: string;
  trains?: any;
  expressionIds: number[];
}

interface ITrain {
  id: number;
  name: string;
  unitIds: number[];
  type: string;
  units?: any;
  expressionIds: number[];
}

interface IUnit {
  id: number;
  name: string;
  nameIdentifier: string;
  isNormal: boolean;
  color: number;
  size: number;
  expressionIds: number[];
  equipmentIds: number[];
  type: string;
  equipments?: any;
}

interface IEquipment {
  id: number;
  name: string;
  nameIdentifier: string;
  isNormal: boolean;
  color: number;
  size: number;
  expressionIds: number[];
  tagIds: number[];
  type: string;
  unitId: number;
}

export interface ITagExpression {
  id: number;
  createdOn?: string | null;
  name: string;
  nameIdentifier: string;
  desc?: string;
  formattedValue: string;
  uom: string | null;
  value: number | null;
  minRange: number | null;
  maxRange: number | null;
  minValue: number | null;
  maxValue: number | null;
  isNormal?: boolean;
  color?: number;
  size?: number;
  isTicketCreated?: boolean;
  isAlertTag?: boolean;
  isDisabled: boolean;
  type: string;
  equipmentId: number;
  unitId: number;
  expressionType: string | null;
  equipmentName: string;
  trainId?: number;
  trainName?: string;
  plantId?: number;
  plantName?: string;

  calculationType?: number;
  listTag?: boolean;
  errorMessage?: any;
  remarks?: any;
  status?: any;
  expression?: string | null;
  tagNameIdentifier?: any;
  unitName?: any;
}

export interface IUserPinLists {
  tagId: number | null;
  expressionId: number | null;
  dashBoardType: number;
}

export interface IUserPinDataLists {
  tagId: number | null;
  expressionId: number | null;
  tagDetail: ITagExpression | IChartData | null;
  expressionDetail: ITagExpression | IChartData | null;
  dashBoardType: number;
}
export interface IPinnedIds {
  tagId: number | null;
  expressionId: number | null;
}

const DataContext = createContext<IDataContext>({
  entireCollection: null,
  plants: [],
  trains: [],
  units: [],
  equipments: [],
  tags: [],
  expressions: [],
  isPastData: false,
  pastDate: null,
  pastDateHandler: (isPast: boolean, dateTime: string | null) => {},
  daySky: 1,
  setDayUnity: (day: number) => {},
  sceneId: 0,
  sceneSwitching: (id: number) => {},
  profileModal: false,
  profileHandler: (open: boolean) => {},
  UnitEquipmentBubbleData: () => [],
  getUnitInfo: (unitId: number) => null,
  getUnitExpressionsInfo: (unitId: number) => [],
  getEquipmentInfo: (equipmentId: number) => null,
  getTagsInfo: (equipmentId: number) => [],
  getEquipmentExpressionsInfo: (equipmentId: number) => [],
  getTagInfo: (tagId: number) => null,
  getExpressionInfo: (expressionId: number) => null,
  getExpressionLists: (expressionIds: number[]) => [],
  chartInfo: (id: number, type: string) => null,
  updateLiveData: () => {},
  loader: false,
  getUpdatedPinnedLists: () => {},
  getPinnedLists: (dashBoardType: number) => [],
  isChartPinned: (id: number, type: string, dashBoardType) => false,
  pinChartHandler: (
    pinningStatus: boolean,
    tagId: number | null,
    expressionId: number | null,
    chartDetail: IChartData | ITagExpression | null,
    dashBoardType: number
  ) => {},
  pinnedLists: [],
  pinMessage: null,
});

interface IPinMessage {
  success: boolean;
  messages: string[];
}

export const DataProvider: React.FC<React.ReactNode> = (props) => {
  const [collection, setCollection] = useState<ICollection | null>(null);

  const [pinnedLists, setUserPinLists] = useState<IUserPinLists[]>([]);
  const [pinnedDataLists, setPinnedData] = useState<IUserPinDataLists[]>([]);
  const [pinMessage, setPinMessage] = useState<IPinMessage | null>(null);

  const [plants, setPlants] = useState<any>([]);
  const [trains, setTrains] = useState<any>([]);
  const [units, setUnits] = useState<any>([]);
  const [equipments, setEquipments] = useState<any>([]);
  const [tags, setTags] = useState<any>([]);
  const [expressions, setExpressions] = useState<any>([]);

  const [isPastData, setPastData] = useState<boolean>(false);
  const [pastDate, setPastDate] = useState<string | null>(null);
  const [daySky, setDaySky] = useState<number>(1);
  const [sceneId, setSceneId] = useState<number>(0);
  const [profileModal, setProfile] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [pinsWithData, setPinsWithData] = useState<any>(null);

  const { DataHandler } = useSignalR();
  const { auth } = useAuth();

  const resetData = useCallback(() => {
    setUnits([]);
    setEquipments([]);
    setTags([]);
    setExpressions([]);
  }, []);

  const getTrains = async () => {
    const response = await axios.get(
      "https://processhub-api.takamulstg.com/TagMeta/GetAllTrains"
    );
    if (response.status === 200) {
      setTrains(response.data);
    }
  };

  const getUnits = async () => {
    const response = await axios.get(
      "https://processhub-api.takamulstg.com/TagMeta/GetAllUnits"
    );
    if (response.status === 200) {
      setUnits(response.data);
    }
  };

  const getEquipments = async () => {
    const response = await axios.get(
      "https://processhub-api.takamulstg.com/TagMeta/GetAllEquipments"
    );
    if (response.status === 200) {
      setEquipments(response.data);
    }
  };

  const getTags = async () => {
    const response = await axios.get(
      "https://processhub-api.takamulstg.com/TagMeta/GetAllTags"
    );
    if (response.status === 200) {
      setTags(response.data);
    }
  };

  const getExpressions = async () => {
    const response = await axios.get(
      "https://processhub-api.takamulstg.com/ExpMeta/GetAllExpressions"
    );
    if (response.status === 200) {
      setExpressions(response.data);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const response = await getTagsModifiedLive([1]);
  //     const response2 = await getExpressionsModifiedLive([26]);
  //   })();
  // }, []);

  // useEffect(() => {
  //   setLoader(true);
  //   resetData();
  //   if (auth) {
  //     getTrains();
  //     getUnits();
  //     getEquipments();
  //     getTags();
  //     getExpressions();
  //   }
  //   setLoader(false);
  // }, [isPastData, pastDate, auth]);

  /* data collection - api calling and splits data as unit, equipments, tags, expressions */
  // const getDataCollection = useCallback(async () => {
  //   const plantId = 1;
  //   const dateTime = isPastData ? pastDate : null;
  //   setLoader(true);
  //   getAllData(plantId, dateTime).then(
  //     (response: any) => {
  //       if (response && response.data) {
  //         if (response.data.trains && Array.isArray(response.data.trains)) {
  //           const dataCollection = response.data;
  //           setCollection(dataCollection);
  //         }
  //       }
  //       setLoader(false);
  //     },
  //     (responseError) => {
  //       setLoader(false);
  //       console.log(responseError);
  //     }
  //   );
  // }, [isPastData, pastDate]);

  // useEffect(() => {
  //   resetData();
  //   if (collection) {
  //     const trainIds: number[] = [];
  //     const trainCollection: Array<ITrain> = collection.trains.map((train) => {
  //       trainIds?.push(train.id);

  //       const unitIds: number[] = [];
  //       const TExpressionIds: number[] = [];

  //       if (train.units && Array.isArray(train.units)) {
  //         const unitCollection: Array<IUnit> = train.units.map((unit) => {
  //           unitIds?.push(unit.id);

  //           const equipmentIds: number[] = [];
  //           const UExpressionIds: number[] = [];
  //           if (unit.equipments && Array.isArray(unit.equipments)) {
  //             const equipmentCollection: Array<IEquipment> =
  //               unit.equipments.map((equip) => {
  //                 const tagIds: number[] = [];
  //                 const EExpressionIds: number[] = [];

  //                 equipmentIds?.push(equip.id);
  //                 const tagsCollection: Array<ITagExpression> = equip.tags.map(
  //                   (tag) => {
  //                     tagIds?.push(tag.id);
  //                     return {
  //                       ...tag,
  //                       type: "tag",
  //                       equipmentId: equip.id,
  //                       unitId: unit.id,
  //                       expressionType: null,
  //                       unitName: unit.name,
  //                       equipmentName: equip.name,
  //                       trainName: train.name,
  //                       plantName: collection.name,
  //                       calculationType: 0,
  //                     };
  //                   }
  //                 );

  //                 setTags((state) => [...state, ...tagsCollection]);

  //                 const expressionsCollection: Array<ITagExpression> =
  //                   equip.expressionLookup.map((expression) => {
  //                     EExpressionIds?.push(expression.id);
  //                     return {
  //                       ...expression,
  //                       type: "expression",
  //                       equipmentId: equip.id,
  //                       unitId: unit.id,
  //                       expressionType: "equipment",
  //                       unitName: unit.name,
  //                       equipmentName: equip.name,
  //                       trainName: train.name,
  //                       plantName: collection.name,
  //                       calculationType: expression.type,
  //                     };
  //                   });
  //                 setExpressions((state) => [
  //                   ...state,
  //                   ...expressionsCollection,
  //                 ]);

  //                 let { id, name, isNormal, color, size } = equip;

  //                 if (equip.tags.length === 1) {
  //                   name = equip.tags[0].name;
  //                   isNormal = equip.tags[0].isNormal;
  //                   color = equip.tags[0].color;
  //                   size = equip.tags[0].size;
  //                 }
  //                 return {
  //                   id,
  //                   name,
  //                   nameIdentifier: unit.name,
  //                   isNormal,
  //                   color,
  //                   size,
  //                   type: "equipment",
  //                   tagIds,
  //                   expressionIds: EExpressionIds,
  //                   unitId: unit.id,
  //                 };
  //               });

  //             setEquipments((state) => [...state, ...equipmentCollection]);
  //           }

  //           const expressionsCollection: Array<ITagExpression> =
  //             unit.expressionLookup.map((expression) => {
  //               UExpressionIds?.push(expression.id);
  //               return {
  //                 ...expression,
  //                 type: "expression",
  //                 unitId: unit.id,
  //                 expressionType: "unit",
  //                 unitName: unit.name,
  //                 equipmentName: "",
  //                 trainName: train.name,
  //                 plantName: collection.name,
  //                 calculationType: expression.type,
  //               };
  //             });
  //           setExpressions((state) => [...state, ...expressionsCollection]);

  //           const { id, name, isNormal, color, size } = unit;
  //           return {
  //             id,
  //             name,
  //             nameIdentifier: "",
  //             isNormal,
  //             color,
  //             size,
  //             type: "unit",
  //             equipmentIds,
  //             expressionIds: UExpressionIds,
  //           };
  //         });
  //         setUnits((state) => [...state, ...unitCollection]);

  //         const expressionsCollection: Array<ITagExpression> =
  //           train.expressionLookup.map((expression) => {
  //             TExpressionIds?.push(expression.id);
  //             return {
  //               ...expression,
  //               type: "expression",
  //               trainId: train.id,
  //               expressionType: "train",
  //               trainName: train.name,
  //               plantName: collection.name,
  //               unitName: "",
  //               equipmentName: "",
  //               calculationType: Number(expression.type),
  //             };
  //           });
  //         setExpressions((state) => [...state, ...expressionsCollection]);
  //       }
  //       const { id, name } = train;
  //       return {
  //         id,
  //         name,
  //         type: "train",
  //         unitIds,
  //         expressionIds: TExpressionIds,
  //       };
  //     });
  //     setTrains((state) => [...state, ...trainCollection]);

  //     const PExpressionIds: number[] = [];
  //     const expressionsCollection: Array<ITagExpression> =
  //       collection.expressionLookup.map((expression) => {
  //         PExpressionIds?.push(expression.id);
  //         return {
  //           ...expression,
  //           type: "expression",
  //           plantId: collection.id,
  //           expressionType: "plant",
  //           plantName: collection.name,
  //           trainName: "",
  //           unitName: "",
  //           equipmentName: "",
  //           calculationType: Number(expression.type),
  //         };
  //       });

  //     setExpressions((state) => [...state, ...expressionsCollection]);
  //     setPlants([
  //       {
  //         id: collection.id,
  //         name: collection.name,
  //         type: "plant",
  //         trainIds,
  //         expressionIds: PExpressionIds,
  //       },
  //     ]);
  //   }
  // }, [resetData, collection]);

  /* user pinned tag/expression lists */
  const getUpdatedPinnedLists = useCallback(async () => {
    await getPinList().then(
      (response) => {
        if (response && response.data && Array.isArray(response.data)) {
          const lists: Array<IUserPinLists> = response.data;
          setUserPinLists(
            lists.map((list) => {
              return {
                tagId: list.tagId,
                expressionId: list.expressionId,
                dashBoardType: list.dashBoardType,
              };
            })
          );
          // updatePinnedData(true);
        }
      },
    );
  }, []);

  useEffect(
    function () {
      if (auth) {
        getUpdatedPinnedLists();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [auth, getUpdatedPinnedLists]
  );

  /* if user logged in - load the data onLoad and onDateChange */
  // useEffect(() => {
  //   if (auth) {
  //     getDataCollection();
  //   }
  // }, [auth, getDataCollection]);

  /* signalR method - to update live data - if it doesn't have past date in calendar */
  const updateLiveData = useCallback(() => {
    if (!isPastData) {
      // get all new api
    }
  }, [isPastData]);

  /* initiate signalR to make listen to tagChanges */
  useEffect(() => {
    if (auth) {
      DataHandler(updateLiveData);
    }
  }, [auth, updateLiveData, DataHandler]);

  /* Method - to update past date details */
  const pastDateHandler = useCallback(
    (isPast: boolean, dateTime: string | null) => {
      setPastData(isPast);
      setPastDate(dateTime);
    },
    []
  );

  /* skybox unity */
  const setDayUnity = useCallback((day: number) => {
    setDaySky(day);
  }, []);

  /* scene switching */
  const sceneSwitching = useCallback((id: number) => {
    setSceneId(id);
  }, []);

  /* profile modal - to pause and resume unity */
  const profileHandler = useCallback((openStatus: boolean) => {
    setProfile(openStatus);
  }, []);

  /* methods to get data in an organized way */

  /* extracted data */
  const UnitEquipmentBubbleData: () => IUnit[] = useCallback(() => {
    const bubbleData: IUnit[] = units.map((unit) => {
      const equipmentData = equipments.filter(
        (equipment) => equipment.unitId === unit.id
      );
      if (equipmentData != null) {
        const unitData = { ...unit, equipments: equipmentData };
        return unitData;
      }
      return { ...unit, equipments: [] };
    });
    return bubbleData;
  }, [units, equipments]);

  const getUnitInfo = useCallback(
    (unitId: number) => {
      const unitInfo = units.filter((unit) => unit.id === unitId);
      if (unitInfo.length) {
        return unitInfo[0];
      }
      return null;
    },
    [units]
  );

  const getUnitExpressionsInfo = (unitId: number) => {
    return expressions.filter(
      (expression) =>
        expression.unitId === unitId && expression.expressionType === "unit"
    );
  };

  const getEquipmentInfo = (equipmentId: number) => {
    const equipmentInfo = equipments.filter(
      (equipment) => equipment.id === equipmentId
    );
    if (equipmentInfo.length) {
      return equipmentInfo[0];
    }
    return null;
  };

  const getTagsInfo = (equipmentId: number) => {
    return tags.filter((tag) => tag.equipmentId === equipmentId);
  };

  const getEquipmentExpressionsInfo = useCallback(
    (equipmentId: number) => {
      return expressions.filter(
        (expression) =>
          expression.equipmentId === equipmentId &&
          expression.expressionType === "equipment"
      );
    },
    [expressions]
  );

  const getTagInfo = useCallback(
    (tagId: number) => {
      const tagInfo = tags.filter((tag) => tag.id === tagId);
      if (tagInfo.length) {
        return tagInfo[0];
      }
      return null;
    },
    [tags]
  );

  const getExpressionLists = useCallback(
    (expressionIds: number[]) => {
      const expressionInfos = expressions.filter((expression) =>
        expressionIds.includes(expression.id)
      );
      return expressionInfos;
    },
    [expressions]
  );

  const getExpressionInfo = useCallback(
    (expressionId: number) => {
      const expressionInfo = expressions.filter(
        (expression) => expression.id === expressionId
      );
      if (expressionInfo.length) {
        return expressionInfo[0];
      }
      return null;
    },
    [expressions]
  );

  const chartInfo = useCallback(
    (id: number, type: string) => {
      switch (type) {
        case "tag":
          const tagInfo = tags.filter((tag) => tag.id === id);
          if (tagInfo.length) {
            return tagInfo[0];
          }
          return null;

        case "expression":
          const expressionInfo = expressions.filter(
            (expression) => expression.id === id
          );
          if (expressionInfo.length) {
            return expressionInfo[0];
          }
          return null;
      }
      return null;
    },
    [tags, expressions]
  );

  /* pin and unpin the tag/expression in side widget - also updating in BE against this user */
  const pinChartHandler = useCallback(
    async (
      pinningStatus: boolean,
      tagId: number | null,
      expressionId: number | null,
      chartDetail: IChartData | ITagExpression | null,
      dashBoardType: number
    ) => {
      let exactType = "";
      if (tagId) {
        exactType = "tag";
      } else {
        exactType = "expression";
      }

      const exactId = tagId || expressionId;

      if (pinningStatus) {
        if (tagId || expressionId) {
          setPinnedData((lists) => {
            return [
              ...lists,
              {
                tagId,
                expressionId,
                tagDetail: tagId ? getTagInfo(tagId) : null,
                expressionDetail: expressionId
                  ? getExpressionInfo(expressionId)
                  : null,
                dashBoardType,
              },
            ];
          });
        }
      } else if (tagId || expressionId) {
        setPinnedData((lists) => {
          const updatedPinList = lists.filter(
            (chart) =>
              !(
                (chart.tagId === exactId && exactType === "tag") ||
                (chart.expressionId === exactId && exactType === "expression")
              )
          );
          return updatedPinList;
        });
      }
      await pinWidgetToUser(tagId, expressionId, pinningStatus, dashBoardType);
      // eslint-disable-next-line
    },
    [pinnedDataLists, getTagInfo, getExpressionInfo]
  );

  const pinWidgetToUser = useCallback(
    async (
      tagId: number | null,
      expressionId: number | null,
      isPinned: boolean,
      dashBoardType: number
    ) => {
      await pinWidget(tagId, expressionId, isPinned, dashBoardType).then(
        (response) => {
          setPinMessage(null);
        },
        (error) => {
          setPinMessage({ success: false, messages: errorHandling(error) });
        }
      );
    },
    []
  );

  const getPinnedLists = useCallback(
    (dashBoardType) => {
      return pinnedDataLists.filter(
        (list) => list.dashBoardType === dashBoardType
      );
    },
    [pinnedDataLists]
  );

  // const getPinsWithData = async () => {
  //   const pins: any = [];
  //   const response: any = await axios.get("/Dashboard/GetPinnedWidgets");
  //   if (response.status === 200) {
  //     response.data.forEach(async (pin: any) => {
  //       console.log(pin);
  //       if (pin.tagId) {
  //         const _response: any = await getTagsModifiedLive([pin.tagId]);
  //         if (_response.status === 200 && _response.data) {
  //           pins.push(_response.data[0]);
  //         } else {
  //           const _response: any = await getExpressionsModifiedLive([
  //             pin.expressionId,
  //           ]);
  //           if (_response.status === 200 && _response.data) {
  //             pins.push({ type: "expression", data: _response.data[0] });
  //           }
  //         }
  //       }
  //     });
  //   }
  //   setPinsWithData(pins);
  // };

  // useEffect(() => {
  //   getPinsWithData();
  // }, []);

  // const getPinsData = async () => {
  //   const _data: any = [];
  //   const response: any = await axios.get("/Dashboard/GetPinnedWidgets");
  //   if (response.status === 200) {
  //     response.data.forEach(async (pin: any) => {
  //       if (pin.dashBoardType === DashBoardType.Unity) {
  //         if (pin.tagId) {
  //           const _response: any = await getTagsModifiedLive([pin.tagId]);
  //           if (_response.status === 200 && _response.data) {
  //             _data.push({ type: "tag", data: _response.data[0] });
  //           }
  //         } else {
  //           const _response: any = await getExpressionsModifiedLive([
  //             pin.expressionId,
  //           ]);
  //           if (_response.status === 200 && _response.data) {
  //             _data.push({ type: "expression", data: _response.data[0] });
  //           }
  //         }
  //       }
  //     });
  //   }
  // };

  /** */
  // const handlePin = async (id, type, targetId) => {
  //   if (type === "tag") {
  //     await axios.post("/Dashboard/pinWidget", {
  //       id,
  //       tagId: targetId,
  //       expressionId: null,
  //       isPinned: true,
  //       dashBoardType: 2,
  //     });
  //   } else {
  //     await axios.post("/Dashboard/pinWidget", {
  //       id,
  //       tagId: null,
  //       expressionId: targetId,
  //       isPinned: true,
  //       dashBoardType: 2,
  //     });
  //   }
  //   getPinsData();
  // };

  // const handleUnPin = async (chart) => {
  //   if (chart.type === "tag") {
  //     await axios.post("/Dashboard/pinWidget", {
  //       tagId: chart.data.id,
  //       expressionId: null,
  //       isPinned: false,
  //       dashBoardType: 2,
  //     });
  //   } else {
  //     await axios.post("/Dashboard/pinWidget", {
  //       tagId: null,
  //       expressionId: chart.data.id,
  //       isPinned: false,
  //       dashBoardType: 2,
  //     });
  //   }
  //   getPinsData();
  // };

  /** */

  const isChartPinned = useCallback(
    (id: number, type: string, dashBoardType: number) => {
      switch (type) {
        case "tag":
          const isTagPinned = pinnedDataLists.find(
            (chart) =>
              chart.tagId === id && chart.dashBoardType === dashBoardType
          );
          if (isTagPinned) {
            return true;
          }
          return false;
        case "expression":
          const isExpressionPinned = pinnedDataLists.find(
            (chart) =>
              chart.expressionId === id && chart.dashBoardType === dashBoardType
          );
          if (isExpressionPinned) {
            return true;
          }
          return false;
        default:
          return false;
      }
    },
    [pinnedDataLists]
  );

  /* update pinned Charts data value */
  useEffect(() => {
    let tagDataLists;
    let expressionDataLists;

    const pinnedTags = pinnedLists
      .filter((list) => list.tagId != null)
      .map((list) => {
        return { id: list.tagId, dashBoardType: list.dashBoardType };
      });
    if (pinnedTags) {
      tagDataLists = pinnedTags.map((tag) => {
        return {
          tagId: tag.id,
          expressionId: null,
          tagDetail: tag.id ? getTagInfo(tag.id) : null,
          expressionDetail: null,
          dashBoardType: tag.dashBoardType,
        };
      });
    }

    const pinnedExpressions = pinnedLists
      .filter((list) => list.expressionId != null)
      .map((list) => {
        return { id: list.expressionId, dashBoardType: list.dashBoardType };
      });
    if (pinnedExpressions) {
      expressionDataLists = pinnedExpressions.map((expression) => {
        return {
          tagId: null,
          expressionId: expression.id,
          expressionDetail: expression.id
            ? getExpressionInfo(expression.id)
            : null,
          tagDetail: null,
          dashBoardType: expression.dashBoardType,
        };
      });
    }

    setPinnedData([...tagDataLists, ...expressionDataLists]);
  }, [pinnedLists, getTagInfo, getExpressionInfo]);

  return (
    <DataContext.Provider
      value={{
        entireCollection: collection,
        plants,
        trains,
        units,
        equipments,
        tags,
        expressions,
        isPastData,
        pastDate,
        pastDateHandler,
        daySky,
        setDayUnity,
        sceneId,
        sceneSwitching,
        profileModal,
        profileHandler,
        UnitEquipmentBubbleData,
        getUnitInfo,
        getUnitExpressionsInfo,
        getEquipmentInfo,
        getTagsInfo,
        getEquipmentExpressionsInfo,
        getTagInfo,
        getExpressionInfo,
        getExpressionLists,
        chartInfo,
        updateLiveData,
        loader,
        getUpdatedPinnedLists,
        getPinnedLists,
        isChartPinned,
        pinChartHandler,
        pinnedLists,
        pinMessage,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);

export default DataContext;
