import axios from "./axios";
import qs from "qs";
import { endpoints } from "./endpoints";

const CLIENT_ID = "ProcessHub.UI";
const CLIENT_SECRET = "ProcessHub-secret";

const {
    REGISTER,
    CONFIRM_EMAIL,
    RESEND_CONFIRM_EMAIL,
    LOGIN,
    GET_USER,
    GET_ALL_USERS,
    GET_AVATAR,
    UPDATE_PROFILE,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_FROM_PROFILE,
    UPDATE_USER_ROLE,
    APPROVE_NEW_USER,
    GET_APPROVAL_PENDING_USERS_LIST,
    DELETE_USER
} = endpoints;

export const ERROR_MESSAGE = {
    'invalid_grant': "Invalid Credentials",
    'code_401': "We are sorry, you are not authorized.",
    'InvalidToken': 'InvalidToken',
    'email_not_confirmed': 'email_not_confirmed',
    'admin_approval_pending': 'Your account is in pending for Admin approval.'
}

export const register = (data) => {
    return axios.post( REGISTER, data);
};

export const confirmEmail = (data) => {
    return axios.post( CONFIRM_EMAIL, data);
};

export const resendEmailConfirm = (data) => {
    return axios.post( RESEND_CONFIRM_EMAIL, data);
}

export const login = (data) => {
    const loginDetails = {
        username: data.email,
        password: data.password,
        grant_type: "password",
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET
    };
    return axios.post(LOGIN,
        qs.stringify(loginDetails), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
}

export const getUser = () => {
    return axios.get( GET_USER);
}

export const ApproveNewUser = (data) => {
    return axios.get(APPROVE_NEW_USER+ `?id=${data}`);
}

export const getApprovalPendingUsersList = () => {
    return axios.get(GET_APPROVAL_PENDING_USERS_LIST);
}

export const UpdateUserRole=(data)=>{
    const details={
        id:data.id,
        role:data.role
    }
    return axios.post( UPDATE_USER_ROLE, details);
}
export const getAllUsers = () => {
    return axios.get( GET_ALL_USERS);
}

export const getAvatar = () => {
    return axios.get( GET_AVATAR);
}

export const deleteUser =(data)=>{
    return axios.get(DELETE_USER+ `?id=${data}`);
}

export const resetPasswordFromProfile = (data) => {
    const details = {
        oldPassword: data.current_password,
        newPassword: data.new_password,
    };
    return axios.post( RESET_PASSWORD_FROM_PROFILE, details);
}

export const forgotPassword = (data) => {
    return axios.post( FORGOT_PASSWORD, null, { params: data });
}

export const resetPassword = (data) => {
    const details = {
        newPassword: data.new_password,
        code: data.code,
        userId: data.userId
    };
    return axios.post( RESET_PASSWORD, details);
};

export const saveProfile = (data) => {
    return axios.post( UPDATE_PROFILE, data);
};