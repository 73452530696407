import styled from 'styled-components';
import theme from 'styles/theme';

export const PhoneNumberContainer = styled.div`
    .react-tel-input .form-control {
        display: block;
        width: 100%;
        height: 44px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.428571;
        color: rgba(255, 255, 255, 0.8);
        background-color: transparent !important;
        background-clip: padding-box;
        border: 3px solid #a1fffd;
        border-radius: 8px;
        box-shadow: none;
    }

    .react-tel-input .flag-dropdown {
        background-color: transparent !important;
        border: 3px solid #a1fffd;
        border-right: 0px;
    }

    .react-tel-input .flag-dropdown .selected-flag:hover {
        background-color: transparent !important;
    }

    .react-tel-input .flag-dropdown .selected-flag .arrow {
        border-top: 4px solid rgba(255, 255, 255, 0.8);
        left: 25px;
    }

    .react-tel-input .flag-dropdown .selected-flag .arrow.up {
        border-bottom: 4px solid rgba(255, 255, 255, 0.8);
        border-top: none;
        transform: inherit;
    }

    .react-tel-input .flag-dropdown.open .selected-flag {
        background-color: transparent !important;
    }

    .react-tel-input .country-list li,
    .react-tel-input .country-list .country.highlight:hover,
    .react-tel-input .country-list .country .dial-code,
    .react-tel-input .country-list .country.highlight:hover .dial-code {
        color: #333333;
    }

    .react-tel-input .country-list .country.highlight ,
    .react-tel-input .country-list .country.highlight .dial-code {
        color: rgba(255, 255, 255, 0.8);
    }

    .react-tel-input .country-list .country.highlight {
        background-color: ${theme.primary};
    }

    .react-tel-input .country-list .country:hover {
        background-color: #a1fffd;
    }
`;