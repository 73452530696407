import { useState, useEffect } from "react";
import ModalOverlay from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import SplashScreen from "components/SplashScreen";

interface ModalProps {
  handleCloseModal: () => void;
  openModal: boolean;
  modalLoader: boolean;
  className?: string;
}

export const Modal: React.FunctionComponent<ModalProps> = (props) => {
  const { handleCloseModal, openModal, modalLoader, children } = props;
  const className = props?.className ? props.className : "";

  const [open, setOpen] = useState(openModal);
  const [loader, setLoader] = useState(modalLoader);

  const handleClose = () => {
    handleCloseModal();
    if (!openModal) setOpen(false);
  };

  useEffect(() => {
    setOpen(openModal);
    setLoader(modalLoader);
  }, [openModal, modalLoader]);

  const activeClass = open ? "active" : "";
  const activeCloseClass = className ? (loader ? "panel-loading" : "") : "";

  return (
    <div>
      <ModalOverlay onClose={handleClose} open={open} id="modalOverlay">
        <div className="modal-flex-container">
          <div className="modal-box">
            <div className="panel active tag-chart-modal">
              <div className="overlay"></div>
              <div className={`panel-body ${className} ${activeCloseClass}`}>
                <div className="panel-close-icon-container">
                  <div
                    className="circle close-cooler-stats"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                </div>
                {className && (
                  <div className={`stats-loader ${activeClass}`}>
                    <SplashScreen open={loader} />
                  </div>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </ModalOverlay>
    </div>
  );
};
