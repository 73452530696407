import { useState } from 'react';
import EditProfile from './EditProfile';
import { saveProfile } from 'service/auth-service';
import { messages } from 'constants/regex';
import { errorHandling } from 'helpers/common';
import { useAuth } from 'context/AuthProvider';

interface ProfileProps {
  handleOpenModal : (pageType: string) => void;
}


const Edit: React.FunctionComponent<ProfileProps> = ({handleOpenModal}) => {
    const { updateUserDetails, auth } = useAuth();
    const [ message, setMessage ] = useState<string[]>([]);
    const [ error, setError ] = useState(false);

    const updateHandler = async (values) => {
      let formData = new FormData();
      const points = `${auth?.user?.points}` || '0';
      formData.append('firstName', values.firstName);
      formData.append('lastName', values.lastName);
      formData.append('phoneNumber', values.phoneNumber);
      formData.append('skypeId', values.skype);
      formData.append('avatar', values.avatar || null);
      formData.append('points', points);
      const profileDetails = { firstName: values.firstName, lastName: values.lastName, 
        phoneNumber: values.phoneNumber, skypeId:values.skype, ianaTimeZone: '', points: auth?.user?.points || 0, 
        email: values.email, avatar: auth?.user?.avatar || '', admin: auth?.user?.admin || false };
      if (typeof values.timezone === 'string') {
        profileDetails.ianaTimeZone = values.timezone;
        formData.append('ianaTimeZone', values.timezone);
      } else {
        formData.append('ianaTimeZone', values.timezone.value);
        profileDetails.ianaTimeZone = values.timezone.value;
      }
        return saveProfile(formData).then(
          async (response) =>  {
            await updateUserDetails(profileDetails);
            setMessage([messages.profile.updateSuccess]);
            setError(false);
          },
          responseError => {
            setMessage(errorHandling(responseError));
            setError(true);
          }
        );
      };

      const updateMessageHandler = () => {
        setMessage([]);
      }

    return (
        <EditProfile updateProfile={updateHandler} message={message} errorMessage={error} updateMessage={updateMessageHandler} handleOpenModal={handleOpenModal} />
    );
};

export default Edit;