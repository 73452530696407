import React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const UnityContext = createContext<any | null>(null);

export const UnityProvider = ({ children }: { children: any }) => {
	const [unityLoaded, setUnityLoaded] = useState<boolean>(false);


	const[currid,setcurrid]= useState<number|null>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);





	

	return (
		<UnityContext.Provider
			value={{
				unityLoaded,
        setUnityLoaded,
		currid,setcurrid,
		anchorEl, setAnchorEl
		
			}}
		>
			{children}
		</UnityContext.Provider>
	);
};

export default function useUnity() {
	return useContext(UnityContext);
}