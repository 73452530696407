import styled from 'styled-components';

export const PasswordContainer = styled.div`
    display: flex;
    align-items: center;

`;

export const EyeIcon = styled.i`
    margin-left: -30px;
`;