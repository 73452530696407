import { createBrowserHistory } from 'history'
import { ERROR_MESSAGE } from 'service/auth-service';
import { AnyObject } from 'yup/lib/object';

export const history = createBrowserHistory();

/* Error Handling - starts */
type IdentityError = {
    code: string,
    description: string
};


const identityErrorsArray = (errors: IdentityError[]) => {
    let errorsArr: string[] = [];
    errors.forEach((err: IdentityError) => {
        if (ERROR_MESSAGE[err.code]) {
            errorsArr.push(ERROR_MESSAGE[err.code]);
        } else {
            errorsArr.push(err.description);
        }
    });

    return errorsArr;
};

const objectErrorsArray = (errors: AnyObject) => {
    let errorsArr: string[] = [];
    errors.forEach((err: string) => {
        errorsArr.push(err);
    });
    return errorsArr;
};

export const errorHandling = (error: any) => {    
    if (!error) {
        return ["Please try again later."];
    }
    if (error.response?.status === 403) {
        return ["You are not authorized user."];
    }
    
    if (error?.response &&
        error.response?.data &&
        error.response.data?.ErrorId) {
        let errors: string[] = [];
        errors = [`Error Id: ${error.response.data.ErrorId}`];
        return errors;
    }

    if (error.response &&
        error.response.data &&
        error.response.data.errors) {
        let errors: string[] = [];
        errors = objectErrorsArray(Object.values(error.response.data.errors));
        return errors;
    }
    if (error.response &&
        error.response.data &&
        error.response.data.identityErrors) {
        let errors: string[] = [];
        errors = identityErrorsArray(Object.values(error.response.data.identityErrors));
        return errors;
    }

    if (error.response &&
        error.response.data &&
        error.response.data.Validations) {
            return error.response.data.Validations;
    }
    
    const resMessage = (error.response &&
        error.response.data &&
        error.response.data?.error) ||
        (error.response &&
            error.response.data ||
            error.response.data?.error) ||
        error.errors ||
        error.toString();

    if (ERROR_MESSAGE[resMessage]) {
        return ([ERROR_MESSAGE[resMessage]]);
    } else if (error.response &&
        error.response.data &&
        error.response.data?.error_description) {
        return [error.response.data.error_description];
    } else {
        return ([resMessage]);
    }
};
/* Error Handling - Ends */

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays: any[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const _byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(_byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};