import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from 'components/Input/TextInput';
import { passwordStrength } from 'check-password-strength';
import Loader from 'components/Loader';
import { messages } from 'constants/regex';
import FormTextFields from 'components/FormTextFields';

type FormProps  = {
    onSubmit: (formValue: FormValues) => void;
    message: string[];
    success: boolean;
    error: boolean;
};

interface FormValues {
    current_password: string;
    new_password: string;
    new_password_check: string;
}
  
interface FormErrors {
    new_password?: string
}

const ResetPassword: React.FunctionComponent<FormProps> = ({ onSubmit, message=[], success=false, error=false }) => {
  const { password } = messages;
  const fields = [    
    {
        key: 'current_password',
        label: 'Current Password',
        placeholder: 'Current Password',
        type: 'password',
        required: true
    },
    {
        key: 'new_password',
        label: 'New Password',
        placeholder: 'New Password',
        type: 'password',
        required: true
    },
    {
        key: 'new_password_check',
        label: 'Repeat New Password',
        placeholder: 'New Password',
        type: 'password',
        required: true
    },
  ];
    return (
      <Formik
        initialValues={{ current_password: '', new_password: '', new_password_check: ''}}
        validate={values => {
            let errors: FormErrors = {};
            if (values.new_password) {
              if(passwordStrength(values.new_password).value !== 'Strong') {
                errors.new_password = messages.password.validMessage;
              }
            }
            return errors;
          }}
        validationSchema={Yup.object({
            new_password: Yup.string().min(password.min, password.minMessage).max(password.max, password.maxMessage),
            new_password_check: Yup
              .string()
              .oneOf([Yup.ref('new_password')], password.matchMessage)
      })}
        onSubmit={ async (values, { resetForm }) => {
          await onSubmit(values);     
          resetForm();
        }}
      >
        {(formik) => (
          <Form>          
            {success && message.map((eachMessage, index) => (
              <div key={`reset_success${index}`} className="pt-4"><p className="text-success">{eachMessage}</p></div>
            ))}
            {error && message.map((eachMessage, index) => (
              <div key={`reset_error${index}`}className="pt-4"><p className="text-danger">{eachMessage}</p></div>
            ))}
            <div className="card-body">
              <FormTextFields fields={fields} />
              
              <TextInput
                label=""
                name="reset"
                id="reset"
                value="Reset Password"
                type="submit"
                className="btn btn-fill btn-primary fullWidth"
                disabled={formik.isSubmitting || !formik.isValid}
              />
              {formik.isSubmitting && <Loader />}
            </div>
            <br/>
          </Form>
          )}
    </Formik>                
    );
}

export default ResetPassword;