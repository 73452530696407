import  { useState, useCallback}  from 'react';
import * as S from './style'
import Header from 'components/Header';
import ModalProfile from "components/Modal/ModalProfile";
import Profile from './Profile';
import { useData } from 'context/DataContext';
interface LayoutProps {

}

const DefaultLayout: React.FunctionComponent<LayoutProps> = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [type, setType] = useState('');

    const { profileHandler } = useData();

    const handleOpenModal = useCallback((pageType) => {
        setModalOpen(true);
        setType(pageType);
        profileHandler(true);
    }, [profileHandler]);

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);        
        profileHandler(false);
    }, [profileHandler]);


    const { children } = props
    return (
        <S.AccountContainer>
            <ModalProfile
                handleCloseModal={handleCloseModal}
                openModal={modalOpen}
            >
                <Profile type={type} handleOpenModal={handleOpenModal} />
            </ModalProfile>
            <Header dashboard={false} handleOpenModal={handleOpenModal} modalOpen={modalOpen} />
            {children}
        </S.AccountContainer>
    );
}

export default DefaultLayout;