import axios from "service/axios";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  getExpressionsModifiedLive,
  getTagsModifiedLive,
} from "service/modified";

const AreaChartContext = createContext<any>(null);

export function AreaChartProvider({ children }) {
  const [values, setValues] = useState<any>(null);
  const [chartDetail, setChartDetail] = useState<any>(null);

  return (
    <AreaChartContext.Provider
      value={{ values, setValues, chartDetail, setChartDetail }}
    >
      {children}
    </AreaChartContext.Provider>
  );
}

export const useAreaChart = () => useContext(AreaChartContext);

export default AreaChartContext;
