import React, { createContext, useContext, useEffect, useState } from 'react';

interface IAiAccuracy {
    value: number;    
};

const AiAccuracyContext = createContext<IAiAccuracy>({
    value: 94
});

function randomIntFromInterval() {
    const min = 88, max = 95;
    return Math.floor(Math.random() * (max - min + 1) + min)
};

export const AiAccuracyProvider: React.FC<React.ReactNode> = (props) => {
    const [ value, setValue ] = useState<number>(0);

    useEffect(() => {
        const setRandomAccuracy = setInterval(() => {
            setValue(randomIntFromInterval);
          }, (0.5 * 1000));
      
          return () => {
            clearInterval(setRandomAccuracy);
          }
    }, []);

    return (
        <AiAccuracyContext.Provider value={{ value }}>
            {props.children}
        </AiAccuracyContext.Provider>
    );
};

export const useData = () => useContext(AiAccuracyContext);

export default AiAccuracyContext;