import { useState, lazy } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import theme from 'styles/theme';
import { Link } from 'react-router-dom';

const Tags = lazy(() => import('../../Tags'));
const Expressions = lazy(() => import('../../Expressions'));
const Assets = lazy(() => import('../../Assets'));

export const DASHBOARD_CONFIG_TABS = {
    'tag': 0,
    'expression': 1,
    'overall': 2
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface IConfiguration {
    tab: number;
  };

const Configuration:React.FC<IConfiguration> = ({ tab }) => {
    const [tabIndex, setTabIndex] = useState(tab);

    // useEffect(() => {
    //     setTabIndex(tab);
    // }, [tab]);

    const tabList = [
        { name: "Tags", component: <Tags />, link: "/dashboard-configuration/tags" },
        { name: "Expressions", component: <Expressions />, link: "/dashboard-configuration/expressions" },
        { name: "Train/Unit/Equipment", component: <Assets />, link: "/dashboard-configuration/all-assets" }
      ];
    
      const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
      };

      
    const sx = {  
        '& .MuiButtonBase-root': {
        color: theme.text_grey,
        }, 
        '& .MuiTab-root.Mui-selected': {
        color: theme.text_grey,
        },
        '& .MuiTabs-flexContainer': {
        gap: "45px"
        },
    };

    return (
            <div className="tags-config-tabs" style={{ padding: '80px 50px'}}>
                <Box sx={{ width: '100%', }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between"  }}>
                <Tabs sx={sx} value={tabIndex} onChange={handleChange} aria-label="basic tabs" TabIndicatorProps={{
                style: { background: "linear-gradient(to right, var(--primary) 0%, var(--primary) 51%, var(--secondary) 100%)", 
                height: "3px",
                border: "1px solid transparent" },
                }}>
                    {tabList.map((tab, index) => (
                      <Link key={`dashboard-tabs-${index}`} to={tab.link}>
                        <Tab label={tab.name} {...a11yProps(index)} />
                      </Link>
                    ))}
                </Tabs>          
                </Box>
                {tabList.map((tab, index) => (
                  <div key={`dashboard-tabs-value-${index}`}className='tabs-panel'>
                    <TabPanel  value={tabIndex} index={index} >
                        {tab.component}
                    </TabPanel>  
                  </div>
                ))}
            </Box>
        </div>
    );
};

export default Configuration;