import React from 'react';
import { useField } from 'formik';
import { PasswordContainer, EyeIcon } from '../Input/common/PasswordInput/PasswordInput.style';

const TextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [showPassword, togglePassword] = React.useState(false)
    let className = props.className || "processhub-form-control";
    return (
      <>
        <div className="row">
          <div className="col-md-12 px-md-1">
            <div className="form-group">
              <label htmlFor={props.id || props.name}>{label}</label>
              {props.type === 'password' ? (
                <PasswordContainer>
                  <input className={className} {...field} {...props} type={showPassword ? 'text' : 'password'} />
                  <EyeIcon
                      className={`icon-eye-${showPassword ? 'closed' : 'open'} right-icon`}
                      onClick={() => togglePassword(!showPassword)}
                  ></EyeIcon>
                </PasswordContainer>
              ) : (props.type === 'textarea' ?
                <textarea className={className} {...field} {...props} />
                :
                <input className={className} {...field} {...props} />
              )}
            </div>
          </div>
        </div>
        {meta.touched && meta.error && (
          <span className="text-danger">
            <ul className="errorlist">
              <li data-testid={`errors-${props.name}`}>{meta.error}</li>
            </ul>
          </span>
        )}        
      </>
    );
  };

export default TextInput;