import { useAuth } from "context/AuthProvider";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Layout from "layouts/Default";

export const RequireAuth = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  return isLoggedIn ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace={true} />
  );
};

export default RequireAuth;
