import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getUser, getAvatar } from "service/auth-service";
import axios from "service/axios";

interface UserDetails {
  email: string;
  phoneNumber: string | null;
  points: number;
  firstName: string;
  lastName: string;
  skypeId: string;
  ianaTimeZone: string | null;
  avatar: string | null;
  admin: boolean;
}

interface AuthDetails {
  user: UserDetails | null;
  tokenDetails: any;
  tokenData: any;
}

export type AuthContextType = {
  auth: null | AuthDetails;
  isLoggedIn: boolean;
  roles: string | null;
  authLogin: (data: null | AuthDetails) => void;
  authLogout: () => void;
  messageHandler: (data: string) => void;
  getMessage: () => string;
  updateUserDetails: (data: UserDetails) => void;
};

const AuthContext = createContext<AuthContextType>({
  auth: null,
  isLoggedIn: false,
  authLogin: (data) => {
    /* auth initialization */
  },
  authLogout: () => {
    /* auth initialization */
  },
  messageHandler: () => {
    /* auth initialization */
  },
  getMessage: () => "",
  roles: null,
  updateUserDetails: (data: UserDetails) => {
    /* auth initialization */
  },
});

const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const AuthProvider: React.FC<React.ReactNode> = (props) => {
  const userToken = (localStorage.getItem("token") && true) || false;

  const [auth, setAuth] = useState<null | AuthDetails>(null);
  const [isLoggedIn, setLoggedIn] = useState<boolean>(userToken);
  const [message, setMessage] = useState<string>("");
  const [roles, setroles] = useState<string | null>("");

  const authLogout = useCallback(() => {
    localStorage.removeItem("token");
    setAuth(null);
    setLoggedIn(false);
  }, []);

  useEffect(() => {
    window.addEventListener("storage", storageEventHandler, false);
  }, []);

  function storageEventHandler() {
    const token = localStorage.getItem("token");
    if (!token) {
      setAuth(null);
      setLoggedIn(false);
    }
  }

  const authInitial = React.useCallback(
    (token = localStorage.getItem("token")) => {
      if (token) {
        const tokenDetails = JSON.parse(token);
        const tokenData = parseJwt(tokenDetails.access_token);
        const getUserDetails = async () => {
          await getUser().then(
            (response: any) => {
              if (response && response.data && response.data.email) {
                let admin = false;
                if (tokenData.user_role) {
                  setroles(tokenData.user_role);
                }
                setAuth({
                  user: { ...response.data, admin },
                  tokenDetails: tokenDetails,
                  tokenData: tokenData,
                });
                setLoggedIn(true);
              } else {
                authLogout();
              }
            },
            (error) => {
              authLogout();
            }
          );
        };
        getUserDetails();
      }
    },
    [authLogout]
  );

  useEffect(() => {
    const baseUrl = localStorage.getItem("baseUrl");
    if (baseUrl) {
      axios.defaults.baseURL = baseUrl;
    } else {
      fetch(`${process.env.PUBLIC_URL}/config.json`)
        .then((res) => res.json())
        .then((data) => {
          localStorage.setItem("baseUrl", data.BASE_URL);
          axios.defaults.baseURL = data.BASE_URL;
        });
    }
    authInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserDetails = async (data: UserDetails) => {
    let avatarImg = auth?.user?.avatar || null;
    const getAvatarDetails = async () => {
      await getAvatar().then(
        (response: any) => {
          if (response && response.data) {
            avatarImg = response.data;
          }
        },
        (error) => {}
      );
    };
    await getAvatarDetails();
    setAuth({
      tokenDetails: auth?.tokenDetails,
      tokenData: parseJwt(auth?.tokenDetails?.access_token),
      user: { ...auth?.user, ...data, avatar: avatarImg },
    });
  };

  const authLogin = (data) => {
    if (data?.tokenDetails) {
      localStorage.setItem("token", JSON.stringify(data.tokenDetails));
      setLoggedIn(true);
      authInitial(JSON.stringify(data.tokenDetails));
      messageHandler("");
    }
  };

  // logging out from all tabs when listening to local storage token item removed
  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "token" && e.oldValue && !e.newValue) {
        authLogout();
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, [authLogout]);

  const messageHandler = (data) => {
    setMessage(data);
  };

  const getMessage = () => {
    return message;
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        isLoggedIn,
        authLogin,
        authLogout,
        messageHandler,
        getMessage,
        updateUserDetails,
        roles,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
