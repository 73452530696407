import TextInput from 'components/Input/TextInput';

export const FormTextFields = ({ fields }) => {
    return (    
        <>
            {fields.map((field: any) => (
                <TextInput
                    key={`fields_${field.key}`}
                    label={field.label}
                    name={field.key}
                    id={field.key}
                    type={field.type}
                    placeholder={field.placeholder}
                    required={field.required}
                />
            ))}
        </>
      );
};
