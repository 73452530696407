import React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const EnergyContext = createContext<any | null>(null);

export const EnergyProvider = ({ children }: { children: any }) => {
    const[mainid,setmainid]=useState<number|null>(null);
	const [isenergychart,setenergychart]=useState<boolean>(false);
	const [iserrormsg,setiserrormsg]=useState<boolean>(false);
	const [errorstr, seterrorstr] = useState<string | null>(null);





	

	return (
		<EnergyContext.Provider
			value={{
                mainid,setmainid,isenergychart,setenergychart, iserrormsg,setiserrormsg, errorstr,seterrorstr
			}}
		>
			{children}
		</EnergyContext.Provider>
	);
};

export default function useEnergy() {
	return useContext(EnergyContext);
}