import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Loader from 'components/Loader';
import TextInput from 'components/Input/TextInput';
import { useAuth } from 'context/AuthProvider';
import PhoneNumber from 'components/PhoneNumber';
import { PasswordContainer, ViewForm } from './EditProfile.style';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { messages } from 'constants/regex';
import TimezoneSelect from 'components/TimezoneSelect';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import theme from 'styles/theme';
import { allTimezones } from 'react-timezone-select';
import AvatarImg from 'assets/images/avatar/default-avatar.png';
import Avatar from '@mui/material/Avatar';
interface EditProfileProps {
    updateProfile: (formValue: FormValues) => void;
    message: string[];
    errorMessage: boolean;
    updateMessage: () => void;
    handleOpenModal : (pageType: string) => void;
}

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;    
    password: string;
    phoneNumber: string;
    skype:string;
    timezone: string;
}
  
interface FormErrors {
    phoneNumber?: string;
}

const EditProfile: React.FunctionComponent<EditProfileProps> = ({ updateProfile, message, errorMessage, updateMessage, handleOpenModal }) => {            
      const { auth } = useAuth();
      const profileTimezone = auth?.user?.ianaTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      const [ timezone, setTimezone ] = useState<any>(profileTimezone);
      const [ editMode, setEditMode ] = useState(false);
      const [ fileDetails, setFileDetails ] = useState({fileName: '', errors: '', value: ''});

      const timeZoneOptions = Object.entries(allTimezones).find(zone => {
          return zone[0] === profileTimezone;
      });

      const timeZoneLabel = (Array.isArray(timeZoneOptions) && timeZoneOptions[1]) || profileTimezone;
      
      const timezoneHandler = (data) => {
        setTimezone(data);
      };
     
      const editHandler = e => {
        e.preventDefault();
        setEditMode(true);
        updateMessage();
      };

      const avatarImg = auth?.user?.avatar ? `data:image;base64,${auth?.user?.avatar}` : AvatarImg;

      let viewFormValues = [
        { label: 'Avatar', value: avatarImg },
        { label: 'Points Earned', value: auth?.user?.points },
        { label: 'First name', value: auth?.user?.firstName },
        { label: 'Last name', value: auth?.user?.lastName },
        { label: 'Email', value: auth?.user?.email },
        { label: 'Skype', value: auth?.user?.skypeId },
        { label: 'Phone number', value: auth?.user?.phoneNumber },
        { label: 'Time zone', value: timeZoneLabel }
      ];

      // Update File details
      const handleFile = event => {
        const fileUploaded = event.target.files[0];
        if(!fileUploaded && !fileDetails.fileName) {
            setFileDetails({fileName: '', errors: 'required', value: ''});
        } else 
        if(fileUploaded) {
            if(fileUploaded.size <= 2 * 1024 * 1024) { // Max 2MB 
                setFileDetails({fileName: fileUploaded.name, errors: '', value: event.target.files[0]});                
            } else {
                setFileDetails({fileName: '', errors: 'fileSize', value: ''});
            }            
        }
      };

      const passwordHandler= () => {
        handleOpenModal('reset-password');
      };      

      return (
        <div>
          <div className="pt-4">
            {message.map((eachMessage, index) => (
              <p key={`register_${index}`} className={errorMessage ? 'text-danger' : 'text-success'}>{eachMessage}</p> 
            ))}
          </div>
          {!editMode && 
            <div style={{ display: "flex", flexDirection: 'row-reverse', cursor: 'pointer' }}>
              <EditTwoToneIcon htmlColor={theme.primary} onClick={editHandler} />
            </div>
          }
          <Formik
            initialValues={{
              firstName: auth?.user?.firstName || '',
              lastName: auth?.user?.lastName || '',
              email: auth?.user?.email || '',
              password: '********',
              skype:auth?.user?.skypeId||'',
              avatar: auth?.user?.avatar || {},
              phoneNumber: auth?.user?.phoneNumber || '', timezone: profileTimezone
            }}
            enableReinitialize
            validate={values => {
              let errors: FormErrors = {};
              if(values.phoneNumber) {
                if (!isValidPhoneNumber(`+${values.phoneNumber}`)) {
                  errors.phoneNumber = messages.phoneNumber.invalid;
                }
              }

              return errors;
            }}          
            onSubmit={ async (values, actions) => {              
              values.timezone = timezone;
              values.avatar = fileDetails.value;
              await updateProfile(values);
              setEditMode(false);
            }}
            onReset={(values, actions) => {
              setEditMode(false);
            }}
          >
            {(formik) => (
              <Form>                          
                  {!editMode && (
                    <ViewForm>
                      {viewFormValues.map((value, index) => (                        
                        <div key={`view_form_val_${index}`} className="form-group">
                          <label>{value.label}</label>
                          {value.label === 'Avatar' ? <Avatar sx={{ height: '100%', width: '100%' }} alt={auth?.user?.firstName} src={avatarImg} />
                           : (
                            <label className="labelValue">
                              {value.value}
                            </label>
                          )}
                        </div>
                      ))}
                    </ViewForm>
                  )}
                  {editMode && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 px-md-1">
                        <div className="form-group">
                          <label htmlFor='avatar'>Avatar</label>
                          <Avatar sx={{ height: '100%', width: '100%' }} alt={auth?.user?.firstName} src={avatarImg} />
                          <div style={{ margin: '10px 0 30px' }}>
                            <input type="file" name="avatar" onChange={handleFile} accept='image/*' />
                          </div>
                        </div>
                      </div>
                    </div>

                    <TextInput 
                        key="firstName"
                        id="firstName"
                        name="firstName"
                        label="First name"
                        placeholder="First name"
                        type="text"
                        required={true}
                    />
                    <TextInput 
                        key="lastName"
                        id="lastName"
                        name="lastName"
                        label="Last name"
                        placeholder="Last name"
                        type="text"
                        required={true}
                    />
                    <TextInput 
                        key="email"
                        id="email"
                        name="email"
                        label="Email"
                        placeholder="Email"
                        type="text"
                        disabled={true}
                    />
                    <PasswordContainer>
                        <TextInput 
                            key="password"
                            id="password"
                            name="password"
                            label="Password"
                            placeholder="Password"
                            type="text"
                            disabled={true}
                        />
                        <div className='password-change'><button type="button" title='Change Password' className='modalOpen' onClick={passwordHandler}>Change</button></div>
                    </PasswordContainer>

                    
                    <TextInput 
                        key="skype"
                        id="skype"
                        name="skype"
                        label="Skype"
                        placeholder="Skype"
                        type="text"
                        required={true}
                    />
   
                    <PhoneNumber 
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        value={auth?.user?.phoneNumber}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <span className="text-danger">
                        <ul className="errorlist">
                          <li data-testid="errors-phoneNumber">{formik.errors.phoneNumber}</li>
                        </ul>
                      </span>
                    )}

                    <TimezoneSelect
                        value={profileTimezone}
                        updateTimezone={timezoneHandler}
                    />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                      <TextInput
                        label=""
                        name="cancel"
                        id="cancel"
                        value="Cancel"
                        type="button"
                        className="btn btn-fill btn-primary"
                        onClick={formik.handleReset}
                      />
                      <TextInput
                        label=""
                        name="save"
                        id="save"
                        value="Save"
                        type="submit"
                        className="btn btn-fill btn-primary"
                        disabled={formik.isSubmitting || !formik.isValid}
                      />
                      {formik.isSubmitting && <Loader />}
                    </div>
                </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      );
  }

export default EditProfile;
