import RequireAuth from "components/RequireAuth";
import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { DASHBOARD_CONFIG_TABS } from "pages/Dashboard/Configuration";
import { USERLOG_CONFIG_TABS } from "pages/UserLogs";
import { useAuth } from "context/AuthProvider";

const Login = lazy(() => import('pages/Login'));
const AccountRegistration = lazy(() => import('pages/AccountRegistration'));
const ForgotPassword = lazy(() => import('pages/Login/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Login/ForgotPassword/ResetPassword'));
const ConfirmEmail = lazy(() => import('pages/AccountRegistration/ConfirmEmail'));
const Stage = lazy(() => import('pages/Stage'));
const Users = lazy(() => import('pages/Users'));
const Tickets = lazy(() => import('pages/Tickets'));
const Health=lazy(() => import('pages/HealthCheck'));
const Alarms = lazy(() => import('pages/Alarms'));
const Unity = lazy(() => import('pages/Unity'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const DashboardConfiguration = lazy(() => import('pages/Dashboard/Configuration'));
const UserLogs = lazy(() => import('pages/UserLogs'));

type routeItem = {
    path: string,
    key: string,
    component: Function,
    protected?: boolean
}

type routes = routeItem & {
    routes?: routeItem[]
}



const ROUTES: routes[] = [
    {
        path: "/",
        key: "ROOT",
        component: () => <Navigate to={"/dashboard"} />,
        protected: true,
    },
    {
        path: "/register",
        key: "register",
        component: AccountRegistration,
    },
    {
        path: "/login",
        key: "LOGIN",
        component: Login
    },
    {
        path: "/confirm-email",
        key: "CONFIRM_EMAIL",
        component: ConfirmEmail
    },
    {
        path: "/forgot-password",
        key: "FORGOT_PASSWORD",
        component: ForgotPassword
    },
    {
        path: "/reset-password",
        key: "FORGOT_PASSWORD_RESET",
        component: ResetPassword,
    },    
    {
        path: "/tickets",
        key: "TICKETS",
        component: () => <Tickets />,
        protected: true
    },   
    {
        path: "/alarms",
        key: "ALARMS",
        component: () => <Alarms />,
        protected: true
    },
    {
        path: "/stages",
        key: "STAGES",
        component: () => <Stage />,
        protected: true
    },
    {
        path: "/health",
        key: "HEALTH",
        component: () => <Health />,
        protected: true
    },
    {
        path: "/users",
        key: "USERS",
        component: () => {<Users />},
        protected: true
    },  

    {
        path: "/3d-dashboard",
        key: "3D_DASHBOARD",
        component: () => <Unity />,
        protected: true
    },
    {
        path: "/dashboard",
        key: "DASHBOARD",
        component: () => <Dashboard />,
        protected: true
    }, 
    {
        path: "/dashboard-configuration",
        key: "Dashboard Configuration",
        component: () => <DashboardConfiguration tab={DASHBOARD_CONFIG_TABS.tag} />,
        protected: true
    },
    {
        path: "/dashboard-configuration/tags",
        key: "Tags",
        component: () => <DashboardConfiguration tab={DASHBOARD_CONFIG_TABS.tag} />,
        protected: true
    },    
    {
        path: "/dashboard-configuration/expressions",
        key: "Expressions",
        component: () => <DashboardConfiguration tab={DASHBOARD_CONFIG_TABS.expression} />,
        protected: true
    },
    {
        path: "/dashboard-configuration/all-assets",
        key: "All Assets",
        component: () => <DashboardConfiguration tab={DASHBOARD_CONFIG_TABS.overall} />,
        protected: true
    },
    {
        path: "/user",
        key: "USER",
        component: () => <UserLogs  tab={USERLOG_CONFIG_TABS.user} />,
        protected: true
    }, 

    {
        path: "/logs",
        key: "LOG",
        component: () => <UserLogs  tab={USERLOG_CONFIG_TABS.logs} />,
        protected: true
    }, 
];

export default ROUTES


export function RenderRoutes({ routes }: { routes: routes[] }) {
    const {roles} = useAuth();
    console.log(roles);
    return (
        <Routes>
            {routes.map((route, i) => {
                return (route.protected ? (
                    <Route key={route.key} element={<RequireAuth />}>
                        {(!(roles?.toLowerCase() ! == "manager" || roles?.toLowerCase() ! == "admin") && route.path==="/user") ? <Route path="*" element={<h1>Not Found!</h1>} />
                        :    <Route path={route.path} element={<route.component />} />
                    }
                    </Route>
                )
                    :
                    <Route key={route.key} path={route.path} element={<route.component />} />)
            })}
            <Route path="*" element={<h1>Not Found!</h1>} />
        </Routes>
    );
}
