import axios from "./axios";
import { endpoints } from "./endpoints"; 

const { SET_TAG_ALARM_STATUS, GET_ALARM_TAGS, SET_ALARM_STATUS, GET_ALARM_COUNT, DELETE_ALARM , ACCEPT_ALL_ALARMS, DELETE_ALL_ALARMS} = endpoints;

export const updateAlarm = (tagId: number, status: number) => {
    return axios.get( SET_TAG_ALARM_STATUS + `?tagId=${tagId}&status=${status}`);
};

export const getAlarmTags = () => {
    return axios.get( GET_ALARM_TAGS);
};

export const updateAlarmHistory = (alarmId, status) => {
    return axios.get( SET_ALARM_STATUS + `?tagHistoryId=${alarmId}&status=${status}`);
};


export const deleteAlarm = (alarmId) => {
    return axios.get( DELETE_ALARM + `?tagHistoryId=${alarmId}`);
};

export const getAlarmCount = () => {
    return axios.get( GET_ALARM_COUNT);
};

export const AcceptAllAlarm = () => {
    return axios.get(ACCEPT_ALL_ALARMS);
};


export const DeleteAllAlarm = () => {
    return axios.get(DELETE_ALL_ALARMS);
};