import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/black-dashboard.css';

fetch(`${process.env.PUBLIC_URL}/config.json`)
  .then((res) => res.json())
  .then((data) => {
    localStorage.setItem("baseUrl", data.BASE_URL);
    localStorage.setItem("webglUrl", data.WEBGL_URL);
    localStorage.setItem("webglPort", data.WEBGL_PORT);

    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
