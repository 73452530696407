import { useState, useEffect, lazy } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import theme from "styles/theme";
import Layout from "layouts/Default";
import { Link } from "react-router-dom";

const Users = lazy(() => import("../Users"));
const Logs = lazy(() => import("../Logs"));

export const USERLOG_CONFIG_TABS = {
  user: 0,
  logs: 1,
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IConfiguration {
  tab: number;
}

const UserLogs: React.FC<IConfiguration> = ({ tab }) => {
  const [tabIndex, setTabIndex] = useState(tab);

  useEffect(() => {
    setTabIndex(tab);
  }, [tab]);

  const tabList = [
    { name: "Users", component: <Users />, link: "/user" },
    { name: "Logs", component: <Logs />, link: "/logs" },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const sx = {
    "& .MuiButtonBase-root": {
      color: theme.text_grey,
    },
    "& .MuiTab-root.Mui-selected": {
      color: theme.text_grey,
    },
    "& .MuiTabs-flexContainer": {
      gap: "45px",
    },
  };

  return (
    <Layout>
      <div className="tags-config-tabs" style={{ padding: "80px 50px" }}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              sx={sx}
              value={tabIndex}
              onChange={handleChange}
              aria-label="basic tabs"
              TabIndicatorProps={{
                style: {
                  background:
                    "linear-gradient(to right, var(--primary) 0%, var(--primary) 51%, var(--secondary) 100%)",
                  height: "3px",
                  border: "1px solid transparent",
                },
              }}
            >
              {tabList.map((tab, index) => (
                <Link to={tab.link}>
                  <Tab
                    key={`dashboard-tabs-${index}`}
                    label={tab.name}
                    {...a11yProps(index)}
                  />
                </Link>
              ))}
            </Tabs>
          </Box>
          {tabList.map((tab, index) => (
            <div className="tabs-panel">
              <TabPanel
                key={`dashboard-tabs-value-${index}`}
                value={tabIndex}
                index={index}
              >
                {tab.component}
              </TabPanel>
            </div>
          ))}
        </Box>
      </div>
    </Layout>
  );
};

export default UserLogs;
