import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

interface ModalProps {
    handleCloseModal: () => void;
    openModal: boolean;
}

const ModalProfile: React.FunctionComponent<ModalProps> = (props) => {
    const { handleCloseModal, openModal,  children } = props;
    const [ open, setOpen ] = useState(openModal);

    const handleClose = () => {
      handleCloseModal();  
      setOpen(false)
    };
  
    useEffect(() => {
      setOpen(openModal)
    }, [openModal]);    
    
    return (
        <Dialog onClose={handleClose} open={open} sx={{ zIndex: 1320 }}>
                {children}
        </Dialog>
    );
}

export default ModalProfile;