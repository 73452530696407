import * as React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { endpoints } from "service/endpoints";
import { useAuth } from "context/AuthProvider";

const { MAIN_HUB } = endpoints;

export type SignalRContextType = {
  alarmMenuHandler: (data: Function) => void;
  alarmListHandler: (data: Function) => void;
  unityDataHandler: (data: Function) => void;
  DataHandler: (data: Function) => void;
  FileUploadHandler: (data: Function) => void;
  connection: null | HubConnection;
};

const SignalRContext = createContext<SignalRContextType>({
  alarmMenuHandler: (type: Function) => {},
  alarmListHandler: (data: Function) => {},
  unityDataHandler: (type: Function) => {},
  DataHandler: (type: Function) => {},
  FileUploadHandler: (type: Function) => {},
  connection: null,
});

export const SignalRProvider: React.FC<React.ReactNode> = (props) => {
  const [connection, setConnection] = useState<null | HubConnection>(null);

  const { auth } = useAuth();

  const alarmMenuHandler = useCallback(
    (data) => {
      if (connection) {
        connection.on("NewAlertTagWarning", function (count) {
          data(count);
        });
      }
    },
    [connection]
  );

  const alarmListHandler = useCallback(
    (data) => {
      if (connection) {
        connection.on("NewAlertTagWarning", function (count) {
          data();
        });
      }
    },
    [connection]
  );

  const unityDataHandler = useCallback(
    (data) => {
      if (connection) {
        connection.on("TagValueChanged", function () {
          data();
        });
      }
    },
    [connection]
  );

  const DataHandler = useCallback(
    (data) => {
      if (connection) {
        connection.on("TagValueChanged", function () {
          data();
        });
      }
    },
    [connection]
  );

  const FileUploadHandler = useCallback(
    (data) => {
      if (connection) {
        connection.on("GenericFunc", function (e) {
          data(e);
        });
      }
    },
    [connection]
  );

  useEffect(() => {
    const baseUrl = localStorage.getItem("baseUrl");
    if (auth) {
      var token = auth?.tokenDetails?.access_token;
      // Start the connection.
      var connectionSignalR = new HubConnectionBuilder()
        .withUrl(baseUrl + MAIN_HUB, { accessTokenFactory: () => token })
        .build();
      connectionSignalR
        .start()
        .then(function () {})
        .catch((error) => {
          console.error(error.message);
        });
      setConnection(connectionSignalR);
    }
  }, [auth]);

  return (
    <SignalRContext.Provider
      value={{
        alarmMenuHandler,
        alarmListHandler,
        unityDataHandler,
        DataHandler,
        FileUploadHandler,
        connection,
      }}
    >
      {props.children}
    </SignalRContext.Provider>
  );
};

export const useSignalR = () => useContext(SignalRContext);

export default SignalRContext;
