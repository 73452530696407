import React, { useState, useEffect } from "react";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import theme from "styles/theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import "moment-timezone";
import { useAuth } from "context/AuthProvider";
import { useData } from "context/DataContext";

export const DateTime: React.FC = () => {
  const { pastDateHandler, setDayUnity } = useData();
  const { auth } = useAuth();
  const [day, setDaySkyBox] = useState(1);
  const defaultTimeZone = "Asia/Kuwait";
  const userTimeZone = auth?.user?.ianaTimeZone || defaultTimeZone;

  const excludeStartDate = new Date(
    moment().set("year", 2022).set("month", 4).set("date", 25).format("lll")
  );
  const excludeEndDate = new Date(
    moment().set({ year: 2022, month: 11, date: 5 }).format("lll")
  );
  const formatter = "YYYY-MM-DD[T]HH:mm:ss";

  const timeZoneTime = () => {
    const localTime = new Date();
    const momentTime = moment(localTime).tz(userTimeZone);

    return new Date(momentTime.format(formatter));
  };

  const [timer, runTimer] = useState(true);
  const [selectedDate, setSelectedDate] = useState(timeZoneTime());
  const [calendar, showCalendar] = useState(false);
  const [isCurrentDate, setIsCurrentDate] = useState(true);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if (paused) {
      pastDateHandler(true, moment(selectedDate).format(formatter));
    }
  }, [paused, pastDateHandler, selectedDate]);

  useEffect(() => {
    if (calendar) {
      runTimer(false);
    } else {
      runTimer(true);
      if (!isCurrentDate) {
        setPaused(false);
      }
    }
  }, [calendar, isCurrentDate]);

  useEffect(() => {
    const checkSaudiDayNight = (dateTime: Date) => {
      const timeSaudi = moment
        .tz(moment(dateTime).format(formatter), userTimeZone)
        .tz("Asia/Kuwait");
      const hour = timeSaudi.hours();
      if (hour > 5 && hour < 18) {
        if (!day) {
          setDaySkyBox(1);
        }
      } else if (day) {
        setDaySkyBox(0);
      }
    };

    checkSaudiDayNight(selectedDate);
  }, [selectedDate, day, userTimeZone]);

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      setSelectedDate((state) => {
        let newDate;
        if (isCurrentDate) {
          newDate = timeZoneTime();
        } else {
          newDate = state;
          var x = newDate.getSeconds() + 1;
          newDate.setSeconds(x);
        }

        return new Date(newDate);
      });
    }, 1000);

    if (!timer || paused) {
      clearInterval(secondsTimer);
    }
    return () => {
      clearInterval(secondsTimer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line
  }, [timer, paused, isCurrentDate, userTimeZone]);

  useEffect(() => {
    setDayUnity(day);
  }, [day, setDayUnity]);

  /* Starts === Updating past data for every 1 minute  - if the timer running for the past date */
  useEffect(() => {
    const SyncPastDataWithTimer = setInterval(() => {
      setSelectedDate((state) => {
        pastDateHandler(true, moment(new Date(state)).format(formatter));
        return state;
      });
    }, 60 * 1000);

    if (isCurrentDate || (!isCurrentDate && paused)) {
      clearInterval(SyncPastDataWithTimer);
    } else {
    }
    return () => {
      clearInterval(SyncPastDataWithTimer);
    };
    // eslint-disable-next-line
  }, [paused, isCurrentDate]);
  /* Ends === Updating past data for every 1 minute  - if the timer running for the past date */

  const clockIconHandler = () => {
    showCalendar((state) => !state);
  };

  const calendarContainer = ({ children }) => {
    return (
      <div className="calendar">
        <div className="sidebar-card">
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  const handleCalendarClose = () => {};

  const handleCalendarOpen = () => {
    showCalendar(true);
  };

  const dateTimeHandler = (date: Date) => {
    setSelectedDate(date);
    pastDateHandler(true, moment(date).format(formatter));
    setIsCurrentDate(false);
  };

  const playPauseHandler = () => {
    setPaused((state) => !state);
  };

  const refreshHandler = () => {
    setSelectedDate(timeZoneTime());
    setIsCurrentDate(true);
    setPaused(false);
    runTimer(true);
    pastDateHandler(false, null);
  };

  const disablePauseBtn = paused ? "play-pause-disable" : "";
  const disablePlayBtn = !paused ? "play-pause-disable" : "";

  return (
    <>
      <div
        className="date-time-container date-time"
        style={{ position: "relative" }}
      >
        {calendar && (
          <div
            style={{
              position: "fixed",
              zIndex: "10",
              inset: 0,
              width: "100%",
              height: "100%",
            }}
            onClick={() => showCalendar(false)}
          />
        )}
        <div
          className="card-content"
          style={{ position: "relative", zIndex: "20" }}
        >
          {!isCurrentDate && (
            <div className="date-time-refresh">
              <RefreshIcon
                htmlColor={theme.primary}
                fontSize="medium"
                onClick={refreshHandler}
              />
            </div>
          )}

          <div className="date-time-play-pause">
            <div
              className={`${
                isCurrentDate ? "play-pause-disable" : disablePlayBtn
              }`}
            >
              <PlayArrowIcon
                htmlColor={theme.primary}
                fontSize="medium"
                onClick={playPauseHandler}
              />
            </div>
            <div
              className={`${
                isCurrentDate ? "play-pause-disable" : disablePauseBtn
              }`}
            >
              <PauseIcon
                htmlColor={theme.primary}
                fontSize="medium"
                onClick={playPauseHandler}
              />
            </div>
          </div>

          <DatePicker
            selected={selectedDate}
            onInputClick={handleCalendarOpen}
            onChange={dateTimeHandler}
            showTimeSelect={true}
            timeIntervals={30}
            className="processhub-form-control"
            dateFormat="MMMM d, yyyy h:mm:ss aa"
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
            maxDate={timeZoneTime()}
            calendarContainer={calendarContainer}
            startOpen={false}
            timeInputLabel="Time:"
            includeDateIntervals={[
              { start: new Date("May 5, 2021"), end: timeZoneTime() },
            ]}
            excludeDateIntervals={[
              { start: excludeStartDate, end: excludeEndDate },
            ]}
            open={calendar}
            showDisabledMonthNavigation
          />

          <div className="date-time-toggler-clock">
            <AccessTimeTwoToneIcon
              htmlColor={theme.primary}
              fontSize="medium"
              onClick={clockIconHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DateTime;
