import axios from "./axios";
import { endpoints } from "./endpoints";
import qs from "qs";

const {
  DATA_COLLECTION,
  PLANTS,
  TRAINS,
  TRAINS_BY_PLANT,
  CREATE_TRAIN,
  UPDATE_TRAIN,
  DELETE_TRAIN,
  UNITS,
  UNITS_BY_TRAIN,
  CREATE_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT,
  EQUIPMENTS,
  EQUIPMENTS_BY_UNIT,
  CREATE_EQUIPMENT,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
  TAGS_COLLECTION_PARENT,
  TAGS_COLLECTION,
  TAGS_BY_EQUIPMENT,
  MAP_EQUIPMENT_TAG,
  UPDATE_TAG,
  CREATE_TAG,
  DELETE_TAG,
  UPLOAD_TAG,
  DOWNLOAD_TAG,
  CLEAR_TAG,
  EXPRESSIONS_COLLECTION,
  UPDATE_EXPRESSION,
  CREATE_EXPRESSION,
  GET_EXPRESSION,
  VALIDATE_EXPRESSION,
  DELETE_EXPRESSION,
  EXPRESSIONS_VALUE_INSTANT_TYPE,
  GET_PINNED_WIDGETS,
  PIN_WIDGET,
  RESET_TAG_RANGE,
  RESET_EXPRESSION_RANGE,
  BUBBLE_COLLECTION,
  GET_EXPRESSION_BY_EQUIPMENT,
  GET_EXPRESSION_BY_UNIT,
  GET_RESET_EXP_OPERATING_RANGES,
  GET_RESET_TAG_OPERATING_RANGES,
  GET_SYSTEM_LOGS,
  GET_ALL_HEALTH,
  REFRESH_HEALTH,
  GET_TAGS_HEALTH,
  GET_USER_ACTIVITY
} = endpoints;

/* Plants, Trains, Units, Equipments - lists */

export const getAllPlants = () => {
  return axios.get(PLANTS);
};

export const getAllTrains = (plantId: number = 0) => {
  if (plantId) {
    return axios.get(TRAINS_BY_PLANT + `?id=${plantId}`);
  }
  return axios.get(TRAINS);
};

export const getExpressionsValueTypeInstant = (data) => {
  return axios.post(EXPRESSIONS_VALUE_INSTANT_TYPE, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteAsset = (
  type: "train" | "unit" | "equipment" | "tag" | "expression",
  id
) => {
  switch (type) {
    case "train":
      return axios.get(DELETE_TRAIN + `?id=${id}`);
    case "unit":
      return axios.get(DELETE_UNIT + `?id=${id}`);
    case "equipment":
      return axios.get(DELETE_EQUIPMENT + `?id=${id}`);
    case "tag":
      return axios.get(DELETE_TAG + `?id=${id}`);
    case "expression":
      return axios.get(DELETE_EXPRESSION + `?id=${id}`);
    default:
      return Promise.reject();
  }
};

export const updateAsset = (
  type: "train" | "unit" | "equipment" | "tag" | "expression",
  data
) => {
  switch (type) {
    case "train":
      return axios.post(UPDATE_TRAIN, data);
    case "unit":
      return axios.post(UPDATE_UNIT, data);
    case "equipment":
      return axios.post(UPDATE_EQUIPMENT, data);
    case "tag":
      return axios.post(UPDATE_TAG, data);
    case "expression":
      return axios.post(UPDATE_EXPRESSION, data);
    default:
      return Promise.reject();
  }
};

export const createAsset = (
  type: "train" | "unit" | "equipment" | "tag" | "expression",
  data
) => {
  switch (type) {
    case "train":
      return axios.post(CREATE_TRAIN, data);
    case "unit":
      return axios.post(CREATE_UNIT, data);
    case "equipment":
      return axios.post(CREATE_EQUIPMENT, data);
    case "tag":
      return axios.post(CREATE_TAG, data);
    case "expression":
      return axios.post(CREATE_EXPRESSION, data);
    default:
      return Promise.reject();
  }
};

export const updateTrain = (data) => {
  return axios.post(UPDATE_TRAIN, data);
};

export const getAllUnits = (trainId: number = 0) => {
  if (trainId) {
    return axios.get(UNITS_BY_TRAIN + `?id=${trainId}`);
  }
  return axios.get(UNITS);
};

export const GetAllEquipments = (unitId: number = 0) => {
  if (unitId) {
    return axios.get(EQUIPMENTS_BY_UNIT + `?id=${unitId}`);
  }
  return axios.get(EQUIPMENTS);
};

/* Expressions - load, update - starts */
export const validateExpression = (expression) => {
  return axios.post(VALIDATE_EXPRESSION, expression);
};

export const getAllExpressions = () => {
  return axios.get(EXPRESSIONS_COLLECTION);
};


export const getExpression = (id: number) => {
  return axios.get(GET_EXPRESSION + `?id=${id}`);
};

export const getExpressionByUnit = (id: number) => {
  return axios.get(GET_EXPRESSION_BY_UNIT + `?id=${id}`);
};

export const getExpressionByEquipment = (id: number) => {
  return axios.get(GET_EXPRESSION_BY_EQUIPMENT + `?id=${id}`);
};

export const resetExpressionRange = (id: number) => {
  return axios.get(RESET_EXPRESSION_RANGE + `?id=${id}`);
};

export const GetresetExpOperatingRange = (id: number) => {
  return axios.get(GET_RESET_EXP_OPERATING_RANGES + `?id=${id}`);
};



/* Expressions - load, update - ends */

/* Tags - load, update, reset - starts */
export const getAllTags = (equipmentId: number) => {
  if (equipmentId) {
    return axios.get(TAGS_BY_EQUIPMENT + `?id=${equipmentId}`);
  }
  return axios.get(TAGS_COLLECTION);
};

/* Tags - load, update, reset - starts */
export const getAllTagsWithParent = (equipmentId: number) => {
  return axios.get(TAGS_COLLECTION_PARENT);
};

export const uploadTag = (data, signal) => {
  return axios.post(UPLOAD_TAG, data, { signal: signal });
};

export const mapTags = (data) => {
  return axios.post(MAP_EQUIPMENT_TAG, data);
};

export const downloadTag = (id) => {
  return axios.get(DOWNLOAD_TAG + `?id=${id}`);
};

export const clearTag = (id) => {
  return axios.get(CLEAR_TAG + `?id=${id}`);
};

export const resetTagRange = (id: number) => {
  return axios.get(RESET_TAG_RANGE + `?id=${id}`);
};

export const getTagsHealth = () =>{
  return axios.get(GET_TAGS_HEALTH);
}

export const GetresetTagOperatingRange = (id: number) => {
  return axios.get(GET_RESET_TAG_OPERATING_RANGES + `?id=${id}`);
};
/* Tags - load, update, reset - ends */

/* Tag/Expression Widget - lists, pin/unpin - starts */
export const getPinList = () => {
  return axios.get(GET_PINNED_WIDGETS);
};

export const pinWidget = (
  tagId: number | null,
  expressionId: number | null,
  isPinned: boolean,
  dashBoardType: number
) => {
  const data = { tagId, expressionId, isPinned, dashBoardType };
  return axios.post(PIN_WIDGET, data);
};
/* Tag/Expression Widget - lists, pin/unpin - ends */

/* Data - load - starts */
export const getAllData = (
  plantId: number = 1,
  dateTime: string | null = null
) => {
  let url: any = DATA_COLLECTION + `?plantId=${plantId}`;
  if (dateTime) {
    url += `&dateTime=${encodeURIComponent(dateTime)}`;
  }

  return axios.get(url);
};
/* Data - load - ends */

/* Bubble - load - starts */
export const getBubbleCollection = () => {
  return axios.get(BUBBLE_COLLECTION);
};
/* Bubble - load - ends */

export const getSystemLogs = ({durType=3,count=10,action=null, module=null}) => {
  let url:any=GET_SYSTEM_LOGS+`?durType=${durType}&count=${count}`;
  if(module!=null){
    url+=`&module=${module}`
  }
  if(action!=null){
    url+=`&action=${action}`
  }

  return axios.get(url);
};

export const getUserActivity = () => {
  return axios.get(GET_USER_ACTIVITY);
};


export const getAllHealth = () => {
  return axios.get(GET_ALL_HEALTH);
};

export const refreshHealth = (id:any) => {
  return axios.get(REFRESH_HEALTH+`?type=${id}`);
};
