import { useState, useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { errorHandling } from 'helpers/common';
import { updateAsset, createAsset, deleteAsset, getExpressionsValueTypeInstant } from 'service/data';
import { ITagExpression } from 'context/DataContext';

export const useGet = <T,>(
    apiMethod: (parentId: number) => Promise<AxiosResponse<unknown, any>>,
    parentId: number,
    refresh: boolean
  ) => {
    const [data, setData] = useState<T[]>([]);
    const [ message, setMessage ] = useState<string[]>([]);
    const [ error, setError ] = useState(false);
    const [ loader, setLoader ] = useState(true);
    
    const errorHandler = useCallback(error => {
        setData([]);
        setMessage(errorHandling(error));
        setError(true);
        setLoader(false);
    }, []);

    const fetchData = useCallback(async () => {
        try {
            setLoader(true); // set loading to true to indicate that the data is being fetched

            apiMethod(parentId).then((response: any) => {
                if (response && response.data && Array.isArray(response.data)) {
                    const responseData: Array<T> = response.data;
                    setData(responseData);
                    setError(false);
                    setMessage([]);
                }
                setLoader(false);
            }, responseError => {
                errorHandler(responseError);
            });
        } catch (error: any) {
          errorHandler(error);
        }
    }, [apiMethod, parentId, errorHandler]);

    useEffect(() => {
      if (refresh) {
          fetchData();            
      }
    }, [refresh, fetchData]);
    
    useEffect(() => {
      if (parentId) {
        fetchData();            
      }
    }, [parentId, fetchData]);

    return { data, loader, message, error };
  };  

  export const useUpdate = <T,>(
    type: "train" | "unit" | "equipment" | "tag" | "expression",
    post: boolean,
    editMode: boolean,
    deleteMode: boolean,
    values: any
  ) => {
    const [ data, setData ] = useState(false);
    const [ message, setMessage ] = useState<string[]>([]);
    const [ error, setError ] = useState(false);
    const [ loader, setLoader ] = useState(false);

    const errorHandler = useCallback(error => {
        setData(false);
        setMessage(errorHandling(error));
        setError(true);
        setLoader(false);
    }, []);

    const postData = useCallback(async () => {
        try {
            setLoader(true); // set loading to true to indicate that the data is being fetched
            if (deleteMode) {
                deleteAsset(type, values).then((response: any) => {
                  setError(false);
                  setLoader(false);
                  setData(true);
              }, responseError => {
                  errorHandler(responseError);
              });
            } else {
              if (editMode) {
                  updateAsset(type, values).then((response: any) => {
                      setMessage(["Updated successfully."]);
                      setError(false);
                      setLoader(false);
                      setData(true);
                  }, responseError => {
                      errorHandler(responseError);
                  });
              } else {
                  createAsset(type, values).then((response: any) => {
                      setMessage(["Created successfully."]);
                      setError(false);
                      setLoader(false);
                      setData(true);
                  }, responseError => {
                      errorHandler(responseError);
                  });
              }
            }
        } catch (error: any) {
          errorHandler(error);
        }
    }, [values, type, editMode]);
    
    useEffect(() => {
      if (post && values) {
        postData();            
      }
    }, [postData, post]);

    return { data, loader, message, error };
  };  

  export const InstantExpressionMiddleware = (expressions: ITagExpression[] | null, type: string, update: boolean) => {
    const [ data, setData ] = useState<ITagExpression[] | null>(expressions);

    useEffect(() => {
      if (update && expressions && type === "expression") {
        const instantTypeExpressionIds = expressions.filter(expression => (expression?.calculationType === 2)).map(expression => expression.id);
        if (instantTypeExpressionIds.length) {
          const callAPI = async (ids) => {
            await getExpressionsValueTypeInstant(ids).then(response => {
            if (response && response.data && Array.isArray(response.data)) {
                const updatedData: ITagExpression[] = response.data;
                const newLists = expressions.map(expression => {
                  if (expression) {
                    const matchedUList = updatedData.filter(UD => UD.id === expression.id);
                    if (matchedUList.length) {
                      return { ...expression, value: matchedUList[0].value, formattedValue: matchedUList[0].formattedValue };
                    }
                  }
                  return expression;
                });
    
                setData(newLists);
              }            
            });  
          };
          callAPI(instantTypeExpressionIds);
        }
      }
    }, [expressions, update, type]);

    return { expressions: data };
  };
