import styled from 'styled-components'

export const PasswordContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    .row {
        flex-grow: 1;
    }
    .password-change {
        position: absolute;
        right: -60px;
    }
`;

export const ViewForm = styled.div`
    .labelValue {
        width: 100%;
        height: 44px;
        font-size: 15px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
    }
`;