import { useState } from 'react';
import ResetPassword from './ResetPassword';
import { resetPasswordFromProfile } from "service/auth-service";
import { messages } from 'constants/regex';
import { errorHandling } from 'helpers/common';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

const ResetPasswordIndex = ({handleOpenModal}) => {
  const [ message, setMessage ] = useState<string[]>([]);
  const [ success, setSuccess ] = useState<boolean>(false);
  const [ error, setError ] = useState<boolean>(false);
  
  const profileHandler = () => {
    handleOpenModal('profile');
  };

  const resetSubmit = async (values) => {   
    return resetPasswordFromProfile(values)
      .then(response => {
          setSuccess(true);
          setError(false);
          setMessage([messages.account.resetSuccess]);
      },
        responseError => {
          setMessage(errorHandling(responseError));
          setError(true);
          setSuccess(false);
        }
    );
  };
  return ( 
    <div className='password-reset-container'>
      <button onClick={profileHandler} title="Go to profile"><ArrowBackIosNewOutlinedIcon fontSize="medium" htmlColor='#00999f'/></button>
      <ResetPassword onSubmit={resetSubmit} message={message} success={success} error={error}/>
    </div>       
  );
}


export default ResetPasswordIndex;