import axios from "service/axios";

function subtractHours(date: any) {
  date.setHours(date.getHours() - 48);
  return date;
}

const dateNow = new Date();
const dateTime = subtractHours(dateNow);

export const getTagsModified = (data: any) => {
  return axios.post(
    `/Dashboard/GetTagData?dateTime=${dateTime.toUTCString()}`,
    data
  );
};

export const getPastDateTags = (data: any,date:any) => {
  return axios.post(
    `/Dashboard/GetTagData?dateTime=${date.toUTCString()}`,
    data
  );
};

export const getTagsModifiedLive = (data: any) => {
  return axios.post("/Dashboard/GetTagData", data);
};

export const getPastDateExpressions = (data: any,date:any) => {
  return axios.post(
    `/Dashboard/GetExpData?dateTime=${date.toUTCString()}`,
    data
  );
};

export const getExpressionsModified = (data: any) => {
  return axios.post(
    `/Dashboard/GetExpData?dateTime=${dateTime.toUTCString()}`,
    data
  );
};

export const getExpressionsModifiedLive = (data: any) => {
  return axios.post("/Dashboard/GetExpData", data);
};

export const getExpByEquipment = (id: any) => {
  return axios.get(`/Dashboard/GetExpDataByEquipment?equipmentId=${id}`);
};

export const getExpByUnit = (id: any) => {
  return axios.get(`/Dashboard/GetExpDataByUnit?unitId=${id}`);
};

export const getTagByEquipment = (id: any) => {
  return axios.get(`/Dashboard/GetTagDataByEquipment?equipmentId=${id}`);
};

export const getExpressionPastData = (
  id: number,
  noOfDays: number,
  dateTime: string | null,
  avgType: string
) => {
  let url: any = `/Dashboard/GetPastExpressionDataWithAvg?expressionId=${id}`;

  url += `&pastNumberOfDays=${noOfDays}`;
  if (dateTime) {
    url += `&dateTime=${encodeURIComponent(dateTime)}`;
  }
  url += `&avgType=${avgType}`;

  return axios.get(url);
};

export const getTagsPastData = (
  id: number,
  noOfDays: number,
  dateTime: string | null,
  avgType: string
) => {
  let url: any = `/Dashboard/GetTagPastDataWithAvg?tagId=${id}`;

  url += `&pastNumberOfDays=${noOfDays}`;
  if (dateTime) {
    url += `&dateTime=${encodeURIComponent(dateTime)}`;
  }
  url += `&avgType=${avgType}`;

  return axios.get(url);
};
