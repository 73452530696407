import React, { useState, useEffect, useCallback, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "context/AuthProvider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AvatarImg from "assets/images/avatar/default-avatar.png";
import logo from "assets/images/logo.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MenuWrapper } from "./Header.style";
import Button from "@mui/material/Button";
import { useSignalR } from "context/SignalRContext";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DateTime from "./DateTime";
import { getAlarmCount } from "service/alarm";
import theme from "styles/theme";
import HelpWindow from "components/HelpWindow";
import { useData } from "context/DataContext";
import { borderRadius } from "@mui/system";
import Alert from "@mui/material/Alert";
// import Banneralert from "components/BannerAlert";
import Unitywindow from "components/Unitylocationpopup";
import { Popover, Popper, Typography } from "@mui/material";
import useUnity, { UnityContext } from "context/UnityContext";
import { RollerShadesSharp } from "@mui/icons-material";

interface HeaderAccountProps {
  dashboard: boolean;
  handleOpenModal?: (pageType: string) => void;
  modalOpen?: boolean;
}

export const Header: React.FunctionComponent<HeaderAccountProps> = (props) => {
  const { auth, authLogout ,roles} = useAuth();
  const { alarmMenuHandler } = useSignalR();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { sceneSwitching } = useData();
  const [alarmCount, setAlarmCount] = useState<number>(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [show3dMenu, set3dMenu] = useState(null);

  const [banner, setbanner] = useState(true);
  // const {open, setopen} = useUnity();

  const isAdmin = auth?.user?.admin || false;
  const isDashboard = props.dashboard;
  const isUnity = window.location.pathname === "/3d-dashboard";

  // const toggleopen = () => {
  //   setopen((state) => !state);
  //   return open;
  // };

  const handleOpenUserMenu = (event) => {
    if (anchorElUser) setAnchorElUser(null);
    else setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const avatarImg = auth?.user?.avatar
    ? `data:image;base64,${auth?.user?.avatar}`
    : AvatarImg;
  const userName = auth?.user?.firstName || "";

  let linkTarget = isUnity ? "_blank" : "_self";

  const dashboard3dHandler = (event) => {
    if (show3dMenu) handleClose3dMenu();
    else set3dMenu(event.currentTarget);
  };

  const handleClose3dMenu = () => {
    set3dMenu(null);
  };

  const { anchorEl, setAnchorEl } = useUnity();

  const handleunityClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const menus = [
    {
      key: "dashboard",
      name: "Dashboard",
      path: "/dashboard",
      islink: true,
      target: linkTarget,
      adminView: false,
      showDropDown: false,
    },
    {
      key: "3d-plant",
      name: "3D Plant",
      path: "/3d-dashboard",
      islink: false,
      target: linkTarget,
      adminView: false,
      showDropDown: true,
    },
    {
      key: "alarms",
      name: "Alarms",
      path: "/alarms",
      islink: true,
      target: linkTarget,
      adminView: false,
      showDropDown: false,
    },
    {
      key: "tickets",
      name: "Tickets",
      path: "/tickets",
      islink: true,
      target: linkTarget,
      adminView: false,
      showDropDown: false,
    },
    {
      key: "user",
      name: "Users",
      islink: true,
      path: "/user",
      target: linkTarget,
      adminView: false,
      showDropDown: false,
    },
    {
      key: "stage",
      name: "Stage",
      path: "/stages",
      islink: true,
      target: linkTarget,
      adminView: false,
      showDropDown: false,
    },
  ];

  const alarmHandler = (count: number) => {
    setAlarmCount(count);
  };

  /* initiate signalR to listen alarm change and receive the alarm count, onload set the alarm count manually */
  useEffect(() => {
    const checkAlarm = async () => {
      getAlarmCount().then(
        (response: any) => {
          if (response && response.data) {
            if (!isNaN(response.data)) {
              const count = response.data;
              setAlarmCount(count);
            }
          }
        },
        (error) => console.log(error)
      );
    };
    checkAlarm();
    alarmMenuHandler(alarmHandler);
  }, [alarmMenuHandler]);

  const userMenu = menus.filter((page) => {
    if((!(roles?.toLowerCase() ! == "manager" || roles?.toLowerCase() ! == "admin") && page.path==="/user")){
      return;
    }
    if (!page.adminView) {
      return page;
    } else if (isAdmin) {
      return page;
    }
    return false;
  });

  useEffect(() => {
    if (!props?.modalOpen) {
      handleCloseUserMenu();
    }
  }, [props?.modalOpen]);

  const profileHandler = () => {
    if (props?.handleOpenModal) {
      props.handleOpenModal("profile");
    }
  };

  useEffect(() => {
    const targetString = "ban*";
    let currentInput = "";

    const handleKeyDown = (event) => {
      const { key } = event;
      if (key) {
        currentInput += key.toLowerCase();
      }

      if (currentInput === targetString) {
        setbanner((state) => !state);
        currentInput = "";
      } else if (!targetString.startsWith(currentInput)) {
        currentInput = "";
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const navigate = useNavigate();

  function handleClick() {
    setAnchorElUser(null);
    navigate("/health");
  }

  const settings = [
    {
      key: "view_profile",
      name: "Profile",
      path: "/user/view-profile",
      disabled: false,
      onClick: () => {
        profileHandler();
      },
    },
    {
      key: "admin_panel",
      name: "Admin Panel",
      disabled: true,
      path: "",
    },
    {
      key: "health_check",
      name: "Health Check",
      path: "/health",
      disabled: false,
      onClick: () => {
        handleClick();
      },
    },
    {
      key: "log_out",
      name: "Log out",
      path: "/login",
      disabled: false,
      onClick: () => {
        authLogout();
      },
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hideMenu = "none";
  const showMenu = "flex";

  const openHelpWindow = useCallback(() => {
    setModalOpen(true);
  }, []);

  const sceneChange = (sceneId) => {
    sceneSwitching(sceneId);
  };

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "black", height: "70px" }}
    >
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            justifyContent: "space-between",
            height: "100%",
            alignItems: "stretch",
          }}
        >
          <Box sx={{ alignSelf: "center" }}>
            <Link to="/">
              <img
                src={logo}
                alt="Processhub"
                className="navbar-brand logo-small"
              />
            </Link>
          </Box>

          <Box
            sx={{
              display: {
                xs: "none",
                md: `${!isDashboard ? hideMenu : showMenu}`,
                xl: hideMenu,
              },
              gap: "5px",
            }}
          >
            {userMenu.map((page) => (
              ( <div
                className={`header_menu_tabs ${
                  alarmCount !== 0 && page.key === "alarms" ? "alarm-blink" : ""
                }`}
                key={page.name}
              >
                <Link target={page.target} to={page.path}>
                  <>
                    <Button>
                      {page.name}
                      <small>
                        {alarmCount !== 0 &&
                          page.key === "alarms" &&
                          `  (${alarmCount})`}
                      </small>
                    </Button>
                  </>
                </Link>
              </div>)
            ))}
          </Box>

          <Box sx={{ display: "flex" }}>
            <div
              className="time-display"
              style={{ marginRight: "40px", width: "380px" }}
            >
              <DateTime />
            </div>

            <Tooltip title="Open">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userName} src={avatarImg} />
                <KeyboardArrowDownIcon style={{ color: "#12e4de" }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{
                mt: "45px",
                "& .MuiMenu-paper": {
                  background: "none",
                  boxShadow: "none",
                },
                "& .MuiMenu-list": {
                  margin: "16px 0px",
                  display: "block",
                  border: "2px solid #A1FFFD",
                  backgroundColor: "black",
                  padding: "0px",
                  borderRadius: "16px",
                },
                "& .MuiMenu-list li a": {
                  background: "none !important",
                  color: "white !important",
                },
                "& .MuiMenu-list li button": {
                  background: "none !important",
                  color: "white !important",
                },
              }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuWrapper>
                {settings.map((setting) =>
                  setting.key === "log_out" ? (
                    <MenuItem
                      key={setting.key}
                      onClick={setting.onClick}
                      className="nav-link"
                    >
                      {isDashboard ? (
                        <button>{setting.name}</button>
                      ) : (
                        <Link to={setting.path}>{setting.name}</Link>
                      )}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      disabled={setting.disabled}
                      key={setting.key}
                      className="nav-link"
                    >
                      <button onClick={setting.onClick}>{setting.name}</button>
                    </MenuItem>
                  )
                )}
              </MenuWrapper>
            </Menu>
          </Box>
        </Toolbar>
      </Container>

      <Box
        className="center-align-menu"
        sx={{
          display: {
            xs: "none",
            md: `${isDashboard ? hideMenu : showMenu}`,
            xl: "flex",
          },
          gap: "5px",
        }}
      >
        {userMenu.map((page) => (
          <div
            className={`header_menu_tabs ${
              alarmCount !== 0 && page.key === "alarms" ? "alarm-blink" : ""
            }`}
            key={page.name}
          >
            {!page.islink ? (
              <>
                <div className="position-relative dashboard-3d-button">
                  <Button onClick={handleunityClick}>
                    {page.name}
                    <small style={{ marginLeft: "2px" }}>
                      {alarmCount !== 0 &&
                        page.key === "alarms" &&
                        `  (${alarmCount})`}
                    </small>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    style={{
                      marginTop: "55px",
                      zIndex: "1000000",
                      marginLeft: "-250px",
                    }}
                  >
                    <Box
                      style={{
                        background: "rgba(0,0,0,0.9)",
                        zIndex: "",
                        border: "2px solid #A1FFFD",
                        padding: "10px",
                      }}
                    >
                      <Unitywindow></Unitywindow>
                    </Box>
                  </Popover>
                </div>
              </>
            ) : (
              <Link target={page.target} to={page.path}>
                <Button>
                  {page.name}
                  <small style={{ marginLeft: "2px" }}>
                    {alarmCount !== 0 &&
                      page.key === "alarms" &&
                      `  (${alarmCount})`}
                  </small>
                </Button>
              </Link>
            )}
          </div>
        ))}
        <div key="help_window" className="help-window" onClick={openHelpWindow}>
          <InfoOutlinedIcon htmlColor={theme.primary} fontSize="medium" />
        </div>
      </Box>

      {/* <SfNav showBanner={true} bannerText='Helloooo' bannerEnableDismiss={false} 
      showSignIn={false} showProfile={false} showBack={false} showNotification={false} showSearch={false}
      stylesMenu={{display:'none'}} stylesSubMenu={{display:'none'}} stylesMenuMobile={{display:'none'}} stylesSubMenuMobile={{display:'none'}} stylesMenuMobileSelected={{display:'none'}} stylesSignIn={{display:'none'}} 
      stylesSearchContainer={{display:'none'}} stylesSearchInput={{display:'none'}} stylesContainerDesktop={{display:'none'}} stylesContainerMobile={{display:'none'}} stylesContainerRightMenu={{display:'none'}} 
      stylesProfilePreamble={{display:'none'}} stylesProfileComponent={{display:'none'}} stylesProfilePicture={{display:'none'}} stylesProfilePictureContainer={{display:'none'}} stylesBack={{display:'none'}} stylesNotificationIcon={{display:'none'}} 
      stylesNotificationBadge={{display:'none'}} stylesNotificationListContainer={{display:'none'}} stylesNotificationRead={{display:'none'}} stylesNotificationUnRead={{display:'none'}} stylesNotificationViewAll={{display:'none'}} 
      stylesBannerContainer={{backgroundColor:'black',paddingTop:'20px'}} stylesBannerCta={{}} stylesBannerText={{}} 
      /> */}

      {/* {banner && (
        <div style={{ marginLeft: "610px", width: "970px", marginTop: "0px" }}>
          <Banneralert />
        </div>
      )} */}

      <HelpWindow modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
    </AppBar>
  );
};
