import { useEffect, useState } from 'react';
import TimezoneSelect from 'react-timezone-select';
import theme from 'styles/theme';

export const TimeZoneSelect = (props) => {
    const [selectedTimezone, setSelectedTimezone] = useState<any>(props.value);

      const customStyles = {
        input: (base, state) => ({
          ...base,
          color: "rgba(255, 255, 255, 0.8)",
        }),
        singleValue:(provided:any) => ({
            ...provided,
            color:'rgba(255, 255, 255, 0.8)',
          }),
        control: (base, state) => ({
            ...base,
            width: "100%",
            height: "44px",
            fontSize: "15px",
            fontWeight: "400",
            color: "rgba(255, 255, 255, 0.8)",
            backgroundColor: "transparent !important",
            backgroundClip: "padding-box",
            border: "3px solid #a1fffd",
            borderRadius: "8px",
            boxShadow: "none",
            background: "#023950",
            "&:hover": {
                border: "3px solid #a1fffd"
            }
          }),
        indicatorContainer:base =>({
            ...base,
            color: "rgba(255, 255, 255, 0.8)",
            "&:hover": { color: "rgba(255, 255, 255, 0.8" }
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "rgba(255, 255, 255, 0.8)"
        }),
        menu: (base) => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
        }),        
        option: (provided, state) => {
          const selectColor = (focusedColor, selectedColor) => {
            if(state.isFocused) {
                return focusedColor;
            }
            return state.isSelected ? selectedColor : null;
          };
          return {...provided,
              backgroundColor: selectColor("#a1fffd", theme.primary),
              color: selectColor("#333333", "rgba(255, 255, 255, 0.8)")
          };
        }
      };

    useEffect(() => {
        props.updateTimezone(selectedTimezone);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimezone]);

    return (
        <div className="row">
          <div className="col-md-12 px-md-1">
            <div className="form-group">
                <label htmlFor="timezone">Time zone</label>              
                <TimezoneSelect
                    styles={customStyles}
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                      ...theme.colors,
                       primary50: '#a1fffd',
                      },
                    })}                                
                />
            </div>
          </div>
        </div>
        
    );
};