import CircularProgress from '@mui/material/CircularProgress';
import { LoaderStyle } from './Loader.style'

const Loader = (props) => {
    const className = props?.className || "";
    return (
        <LoaderStyle className={className}>
            <div>
                <CircularProgress />
            </div>
        </LoaderStyle>
    )
};

export default Loader;