import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { PhoneNumberContainer } from './PhoneNumber.style';

export const PhoneNumber = (props) => {
    const changeHandler = (phone) => {
        props.setFieldValue('phoneNumber', phone);
    };

    const blurHandler = () => {
        props.setFieldTouched('phoneNumber', true);
    };
    
    return (
        <PhoneNumberContainer className="row">
          <div className="col-md-12 px-md-1">
            <div className="form-group">
                <label htmlFor="phoneNumber">Phone number</label>              
                <PhoneInput
                    country={'sa'}
                    onChange={phone => {
                        changeHandler(phone);
                    }}
                    onBlur={blurHandler}
                    value={props.value}
                />
            </div>
          </div>
        </PhoneNumberContainer>        
    );
}

