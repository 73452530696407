import axios from "service/axios";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  getExpressionsModifiedLive,
  getTagsModifiedLive,
} from "service/modified";
import { useAuth } from "./AuthProvider";

const PinsContext = createContext<any>(null);

export function PinsProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [allPinsMeta, setAllPinsMeta] = useState<any>(null);
  const [allPinsData, setAllPinsData] = useState<any>(null);
  const [unityPins, setUnityPins] = useState<any>(null);
  const [dashboardPins, setDashboardPins] = useState<any>(null);

  const {isLoggedIn} = useAuth();

  const getAllPinsMeta = async () => {
    const response: any = await axios.get("/Dashboard/GetPinnedWidgets");
    if (response.status === 200) {
      setAllPinsMeta(response.data);
    }
  };

  useEffect(() => {
    if(isLoggedIn){
      getAllPinsMeta();
    }
  }, []);

  useEffect(() => {
    async function getPinData() {
      let a = allPinsMeta.length;
      const _pinsWithData: any = [];
      while (a >= 0) {
        if (allPinsMeta[a]?.tagId) {
          const response = await getTagsModifiedLive([allPinsMeta[a].tagId]);
          if (response.status === 200) {
            _pinsWithData.push({
              meta: allPinsMeta[a],
              data: response.data,
            });
          }
        }
        if (allPinsMeta[a]?.expressionId) {
          const response = await getExpressionsModifiedLive([
            allPinsMeta[a].expressionId,
          ]);
          if (response.status === 200) {
            _pinsWithData.push({
              meta: allPinsMeta[a],
              data: response.data,
            });
          }
        }
        a--;
      }
      setAllPinsData(_pinsWithData);
    }
    if (allPinsMeta && allPinsMeta.length > 0) {
      getPinData();
    } else {
      setAllPinsData([]);
      setUnityPins([]);
      setDashboardPins([]);
    }
  }, [allPinsMeta]);

  useEffect(() => {
    if (allPinsData) {
      setUnityPins(
        allPinsData.filter((pin: any) => pin.meta.dashBoardType === 2)
      );
      setDashboardPins(
        allPinsData.filter((pin: any) => pin.meta.dashBoardType === 1)
      );
    }
    setLoading(false);
  }, [allPinsData]);

  const pin = async (tagId, expressionId, dashBoardType) => {
    if (dashBoardType === 2 && dashboardPins.length > 9) {
      return;
    }
    const response: any = await axios.post("/Dashboard/PinWidget", {
      tagId: tagId ? tagId : null,
      expressionId: expressionId ? expressionId : null,
      isPinned: true,
      dashBoardType,
    });
    if (response.status === 200) {
      getAllPinsMeta();
    }
  };

  const pinTag = async (tagId, pinStatus) => {
    const response: any = await axios.post("/Dashboard/PinWidget", {
      tagId: tagId,
      expressionId: null,
      isPinned: pinStatus,
      dashBoardType: 1,
    });
    if (response.status === 200) {
      getAllPinsMeta();
    }
  };

  const unPin = async (tagId, expressionId, dashBoardType) => {
    const response: any = await axios.post("/Dashboard/PinWidget", {
      tagId: tagId ? tagId : null,
      expressionId: expressionId ? expressionId : null,
      isPinned: false,
      dashBoardType,
    });
    if (response.status === 200) {
      getAllPinsMeta();
    }
  };

  return (
    <PinsContext.Provider
      value={{ pin, unPin, pinTag, unityPins, dashboardPins, loading }}
    >
      {children}
    </PinsContext.Provider>
  );
}

export const usePins = () => useContext(PinsContext);

export default PinsContext;
