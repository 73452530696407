import React from 'react';
import Modal from "components/Modal";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

interface IHelpWindow {
    handleCloseModal: () => void;
    modalOpen: boolean;
}

const HelpWindow: React.FC<IHelpWindow> = (props) => {
    const { handleCloseModal, modalOpen } = props;
    
    return (
        <Modal 
          handleCloseModal={handleCloseModal}
          openModal={modalOpen}
          modalLoader={false}
          className="gauge-chart-6-tags"
        >          
            <div className="tags-chart-wrapper">
                <div className={`tag-chart-top-container d-flex flex-wrap tags-ticket-creation-popup`}>             
                    <div className="h-100 w-100">
                        <div className="ticket-panel text-left h-100">
                            <h2 className="panel-heading">Key Guide</h2>

                            <div >
                                <table className='w-100 help-window-table'>                                
                                    <thead>
                                        <tr>
                                            <th scope="col"><h4>Action</h4></th>
                                            <th scope="col"></th>
                                            <th scope="col"><h4>Keyboard/Mouse</h4></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-0'>
                                            <td >Horizontal Movement</td>
                                            <td className='help-icons-td'>
                                                <div><span>a</span></div>
                                                <div><span>d</span></div>
                                            </td>
                                            <td>a - left, d - right</td>
                                        </tr>
                                        <tr>
                                            <td ></td>
                                            <td className='help-icons-td'>
                                                <div style={{ padding: "0 1px" }}><KeyboardArrowLeftOutlinedIcon /></div>
                                                <div style={{ padding: "0 1px" }}><KeyboardArrowRightOutlinedIcon /></div>
                                            </td>
                                            <td>left-arrow - left, right-arrow - right</td>
                                        </tr>
                                        <tr  className='border-0'>
                                            <td >Vertical Movement</td>
                                            <td className='help-icons-td'>
                                                <div><span>w</span></div>
                                                <div><span>d</span></div>
                                            </td>
                                            <td>w - forward, s - backward </td>
                                        </tr>
                                        <tr>
                                            <td ></td>
                                            <td className='help-icons-td'>
                                                <div style={{ padding: "0 1px" }}><KeyboardArrowUpOutlinedIcon /></div>
                                                <div style={{ padding: "0 1px" }}><KeyboardArrowDownOutlinedIcon /></div>
                                            </td>
                                            <td>up-arrow - forward, down-arrow - backward</td>
                                        </tr>
                                        <tr>
                                            <td >Fly</td>
                                            <td className='help-icons-td'>
                                                <div><span>f</span></div>
                                            </td>
                                            <td>f - to fly</td>
                                        </tr>
                                        <tr>
                                            <td >Change Point of View</td>
                                            <td className='help-icons-td'>
                                                <div><span>c</span></div>
                                            </td>
                                            <td>c - to switch between first person view and third person view</td>
                                        </tr>
                                        <tr>
                                            <td >Medium Speed</td>
                                            <td className='help-icons-td'>
                                                Movement +&nbsp;<div><span>Left Shift</span></div>
                                            </td>
                                            <td>Movement key + Left Shift</td>
                                        </tr>
                                        <tr>
                                            <td >High Speed</td>
                                            <td className='help-icons-td'>
                                                Movement +&nbsp;<div><span>Right Shift</span></div>
                                            </td>
                                            <td>Movement key + Right Shift</td>
                                        </tr>
                                        <tr>
                                            <td >Tags</td>
                                            <td className='help-icons-td'>
                                                Mouse <KeyboardArrowRightOutlinedIcon /> <div><span>Left Click</span></div>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td >Expression LookUp</td>
                                            <td className='help-icons-td'>
                                                Mouse <KeyboardArrowRightOutlinedIcon /> <div><span>Right Click</span></div>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td >Release cursor</td>
                                            <td className='help-icons-td'>
                                                <div><span>Esc</span></div>
                                            </td>
                                            <td>Escape key - to release cursor</td>
                                        </tr>
                                        <tr className='border-0'>
                                            <td >Lock cursor</td>
                                            <td className='help-icons-td'>
                                                Mouse <KeyboardArrowRightOutlinedIcon /> <div><span>Double Click</span></div>
                                            </td>
                                            <td>Click anywhere in the 3d environment</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default HelpWindow;