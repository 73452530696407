export let endpoints = {
  REGISTER: "/auth/register",
  CONFIRM_EMAIL: "/auth/confirmemail",
  RESEND_CONFIRM_EMAIL: "/auth/resendmailconfirmation",
  LOGIN: "/connect/token",
  GET_USER: "/user/get",
  UPDATE_USER_ROLE: "/user/updateuserrole",
  GET_ALL_USERS: "/user/getall",
  APPROVE_NEW_USER: "/user/approvenewuser",
  GET_APPROVAL_PENDING_USERS_LIST: "/user/getapprovalpendinguserlist",
  GET_AVATAR: "/user/getavatar",
  RESET_PASSWORD_FROM_PROFILE: "/auth/resetpasswordfromprofile",
  FORGOT_PASSWORD: "/auth/forgotpassword",
  RESET_PASSWORD: "/auth/resetpassword",
  UPDATE_PROFILE: "/user/edit",
  DELETE_USER: "/User/DeleteUser",

  /* data collection */
  DATA_COLLECTION: "/Dashboard/GetPlantTrainUnitsEquipmentsTagsByPlant",

  PLANTS: "/TagMeta/GetAllPlants",

  TRAINS: "/TagMeta/GetAllTrains",
  TRAINS_BY_PLANT: "/TagMeta/GetTrainsByPlantId",
  CREATE_TRAIN: "/TagMeta/CreateTrain",
  UPDATE_TRAIN: "/TagMeta/UpdateTrain",
  DELETE_TRAIN: "/TagMeta/DeleteTrain",

  UNITS: "/TagMeta/GetAllUnits",
  UNITS_BY_TRAIN: "/TagMeta/GetUnitsByTrainId",
  CREATE_UNIT: "/TagMeta/CreateUnit",
  UPDATE_UNIT: "/TagMeta/UpdateUnit",
  DELETE_UNIT: "/TagMeta/DeleteUnit",

  EQUIPMENTS: "/TagMeta/GetAllEquipments",
  EQUIPMENTS_BY_UNIT: "/TagMeta/GetEquipmentsByUnitId",
  CREATE_EQUIPMENT: "/TagMeta/CreateEquipment",
  UPDATE_EQUIPMENT: "/TagMeta/UpdateEquipment",
  DELETE_EQUIPMENT: "/TagMeta/DeleteEquipment",

  TAGS_COLLECTION: "/TagMeta/GetAllTags",
  TAGS_COLLECTION_PARENT: "/TagMeta/GetAllTagsWithParent ",
  TAGS_BY_EQUIPMENT: "/TagMeta/GetTagsByEquipmentsId",
  MAP_EQUIPMENT_TAG: "/TagMeta/MapEquipmentTag",
  CREATE_TAG: "/TagMeta/CreateTag",
  UPDATE_TAG: "/TagMeta/UpdateTag",
  UPLOAD_TAG: "/TagData/Upload",
  RESET_TAG_RANGE: "/TagMeta/ResetTagRange",
  DELETE_TAG: "/TagMeta/DeleteTag",
  DOWNLOAD_TAG: "/TagData/DownloadTagData",
  CLEAR_TAG: "/TagData/ClearTagData",
  GET_RESET_TAG_OPERATING_RANGES: "/TagMeta/GetResetTagOperatingRanges",
  GET_TAGS_HEALTH:"/Dashboard/GetTagsHealth",

  EXPRESSIONS_COLLECTION: "/ExpMeta/GetAllExpressions",
  CREATE_EXPRESSION: "/ExpMeta/CreateExpression",
  GET_EXPRESSION: "/ExpMeta/GetExpression",
  UPDATE_EXPRESSION: "/ExpMeta/UpdateExpression",
  VALIDATE_EXPRESSION: "/ExpMeta/ValidateExpression",
  DELETE_EXPRESSION: "/ExpMeta/DeleteExpression",
  EXPRESSIONS_VALUE_INSTANT_TYPE: "/Dashboard/GetExpData",
  RESET_EXPRESSION_RANGE: "/ExpMeta/ResetOperatingRanges",
  GET_EXPRESSION_BY_UNIT: "/ExpMeta/GetExpressionByUnit",
  GET_EXPRESSION_BY_EQUIPMENT: "/ExpMeta/GetExpressionByEquipment",
  GET_RESET_EXP_OPERATING_RANGES: "/ExpMeta/GetResetExpOperatingRanges",

  /* Unity Bubbles */
  GET_ALL_BUBBLES: "/Unity/GetAllBubbles",
  GET_BUBBLE: "/Unity/GetBubble",
  CREATE_BUBBLE: "/Unity/CreateBubble",
  UPDATE_BUBBLE: "/Unity/UpdateBubble",
  UPDATE_BUBBLE_POSITION: "/Unity/UpdateBubbleCordinates",
  DELETE_BUBBLE: "/Unity/DeleteBubble",

  /* Unity Labels */
  GET_ALL_LABELS: "/Unity/GetAllLabels",
  GET_LABEL: "/Unity/GetLabel",
  CREATE_LABEL: "/Unity/CreateLabel",
  UPDATE_LABEL: "/Unity/UpdateLabel",
  UPDATE_LABEL_POSITION: "/Unity/UpdateLabelCordinates",
  DELETE_LABEL: "/Unity/DeleteLabel",

  /* Unity Notes */
  GET_ALL_NOTES: "/Unity/GetAllNotes",
  GET_NOTE: "/Unity/GetNote",
  CREATE_NOTE: "/Unity/CreateNote",
  UPDATE_NOTE: "/Unity/UpdateNote",
  DELETE_NOTE: "/Unity/DeleteNote",
  DOWNLOAD_NOTE_FILE: "/Unity/getFile",

  /* Popup Chart */
  TAG_PAST_DATA: "/Dashboard/GetTagPastDataWithAvg",
  EXPRESSION_PAST_DATA: "/Dashboard/GetPastExpressionDataWithAvg",
  TAG_FUTURE_DATA: "/Dashboard/GetTagPastDataWithAvgFuture",
  EXPRESSION_FUTURE_DATA: "/Dashboard/GetPastExpressionDataWithAvgFuture",

  /* bubble collection */
  BUBBLE_COLLECTION: "/Unity/GetAllBubbles",

  /* Enabling/Disabling feature - Tag */
  UPDATE_TAG_STATUS: "/TagMeta/SetTagDisabled",

  /* Ticket System */
  CREATE_TICKET: "/ticket/createticket",
  UPDATE_TICKET: "/ticket/updateticket",
  GET_ALL_TICKETS: "/ticket/getallticket",
  GET_MY_TICKETS: "/ticket/getmyticket",
  CLOSE_TICKET: "/ticket/closeticket",
  REJECT_TICKET: "/ticket/rejectticket",
  TOP_USERS: "/ticket/GetUsersWithTopPoints",
  DELETE_TICKET: "/Ticket/DeleteTicket",

  /* Stage */
  GET_STAGE: "/stage/download",
  UPLOAD_STAGE: "/stage/upload",
  REMOVE_STAGE: "/stage/Remove",
  GET_ALL_FILES_STAGE: "/stage/GetAllFiles",
  UPDATE_FILE_URL_STAGE: "/stage/updateFileUrl",
  GET_FILE_URL_STAGE: "/stage/getFileUrl",
  SET_AS_PRIMARY: "Stage/SetAsPrimary",

  /* SignalR */
  MAIN_HUB: "/MainHub",

  /* Dashboard page */
  TRAIN_DETAILS: "/Dashboard/GetUnitsEquipmentsTagsByTrain",

  /* Alarm */
  SET_TAG_ALARM_STATUS: "/AlertTag/SetAlertStatus",
  GET_ALARM_TAGS: "/AlertTag/GetAlertTagHistories",
  SET_ALARM_STATUS: "/AlertTag/SetAlertHistoryStatus",
  GET_ALARM_COUNT: "/AlertTag/GetActiveAlertCount",
  DELETE_ALARM: "/AlertTag/DeleteAlertHistory",
  ACCEPT_ALL_ALARMS: "/AlertTag/AcceptAllAlerts",
  DELETE_ALL_ALARMS: "/AlertTag/DeleteAllAlerts",


  // HEALTH CHECK
  GET_ALL_HEALTH: "/Health/getAll",
  REFRESH_HEALTH: "/Health/Get",


  /* Widgets */
  PIN_WIDGET: "/Dashboard/PinWidget",
  GET_PINNED_WIDGETS: "/Dashboard/GetPinnedWidgets",

  GET_SYSTEM_LOGS: "/SystemLog/GetSystemLogsByDateTime",
  GET_USER_ACTIVITY: "/SystemLog/GetUserActivity"
};

let API_BOOK = {
  LOGIN_API: {
    url: endpoints.LOGIN,
    method: "POST",
    data: null,
  },
};

export default API_BOOK;
